import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authAction } from "./state/auth_slice";
import loading1 from "../img/loading1.gif";

function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [empId, setEmpId] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "Employee Login";
  }, []);

  const Login = () => {
    if (empId == "") {
      document.getElementById("empIdErr").style.display = "block";
      document.getElementById("empId").style.borderColor = "red";
    } else {
      document.getElementById("empIdErr").style.display = "none";
      document.getElementById("empId").style.borderColor = "lightgray";
    }
    if (password == "") {
      document.getElementById("passwordErr").style.display = "block";
      document.getElementById("password").style.borderColor = "red";
    } else {
      document.getElementById("passwordErr").style.display = "none";
      document.getElementById("password").style.borderColor = "lightgray";
    }

    if (empId != "" && password != "") {
      setIsLoading(true);
      axios
        .post("https://www.bdc.et/bdc-api/login.php", {
          emp_id: empId,
          password: password,
        })
        .then((res) => {
          if (res.data == 0) {
            document.getElementById("loginErr").style.display = "block";
          } else {
            navigate("/employee_dashboard", {});
            document.getElementById("loginErr").style.display = "none";
            dispatch(authAction.login());
            dispatch(
              authAction.setUser({
                fullName:
                  res.data[0][1] + " " + res.data[0][2] + " " + res.data[0][3],
                phoneNumber: res.data[0][6],
                id: res.data[0][0],
                idNumber: res.data[0][8],
                dep: res.data[0][9],
              })
            );

            axios
              .post("https://www.bdc.et/bdc-api/get_employee_role.php", {
                id: res.data[0][0],
              })
              .then((res2) => {
                if (res2.data) {
                  dispatch(
                    authAction.setRole({
                      tab: res2.data,
                    })
                  );
                }
              });
          }
        })
        .catch((err) => alert(err))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div
      className="cont-all"
      style={{
        width: "400px",
        float: "center",
        margin: "auto",
        alignItems: "center",
        top: "100px",
        position: "relative",
      }}
    >
      <h2
        className="pa-h"
        style={{
          backgroundColor: "gray",
          color: "white",
          margin: 0,
          padding: "5px 0px",
          width: "100%",
        }}
      >
        Login Information
      </h2>
      <div style={{ display: "inline-flex" }}>
        <div style={{ width: "400px" }} id="regCont">
          <div className="cont2">
            <p className="reg-err" id="loginErr">
              *Wrong employee Id or password!
            </p>
            <p className="reg-err" id="empIdErr">
              *This Information is required!
            </p>
            <input
              className="txt-input"
              id="empId"
              type="text"
              onChange={(e) => {
                setEmpId(e.target.value);
              }}
              style={{ marginTop: "20px" }}
              placeholder="Enter Your ID"
              required
            />
            <br />
            <p className="reg-err" id="passwordErr">
              *This Information is required!
            </p>
            <input
              className="txt-input"
              id="password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Enter Your Password"
              required
            />

            <br />
            {isLoading ? (
              <div style={{ width: "100%", textAlign: "center" }}>
                <img src={loading1} alt="login..." style={{ width: "100px" }} />
              </div>
            ) : (
              <input
                type="button"
                id="register"
                value="Login"
                className="btn1"
                style={{ margin: "20px 0" }}
                onClick={Login}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
