import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import loading2 from "../img/loading2.gif";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faStar, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function ExpenseSettleList() {
  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const current_date = moment(Date()).format("DD-MM-YYYY");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [data, setData] = useState([]);
  const [project, setProject] = useState("All");
  const [resource, setResource] = useState("All");
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [dialog2, setDialog2] = useState([]);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [dialog3, setDialog3] = useState([]);
  const [settleFor, setSettleFor] = useState("All");
  const [employee, setEmployee] = useState("All");
  const [projectName, setProjectName] = useState("All");
  const [resourcName, setResourceName] = useState("All");
  const [resType, setResType] = useState("Material");
  const [resCategory, setResCategory] = useState("Civil");

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/ወጭ /የተላከ ዝርዝር";

    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_settled_expense_list.php", {
        settleFor: settleFor,
        project: project,
        resource: resource,
      })
      .then((res) => setData(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  const OpenDialog = () => {
    setOpenDialog(true);
    setIsLoading2(true);

    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => setDialog(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const OpenDialog2 = () => {
    setOpenDialog2(true);
    setResType("Material");
    setResCategory("Civil");
    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_resource_list.php", {
        resType: "Material",
        resCategory: "Civil",
      })
      .then((res) => setDialog2(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const OpenDialog3 = () => {
    setOpenDialog3(true);
    setIsLoading2(true);

    axios
      .post("https://www.bdc.et/bdc-api/get_employee_list.php", {})
      .then((res) => setDialog3(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const DeleteReq = (id, reqId, docNumber) => {
    if (reqId) {
      axios
        .post("https://www.bdc.et/bdc-api/post_delete_expense_settle.php", {
          id: id,
          reqId: reqId,
          docNumber: docNumber,
        })
        .then((res) => setData(res.data))
        .catch((err) => alert(err));
    }
  };
  return (
    <>
      {tab.includes("tab42") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    width: "550px",
                  }}
                >
                  <div
                    className="brdr-btm"
                    style={{ display: "inline-flex", width: "100%" }}
                  >
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      የፕሮጀክት ዝርዝር
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        margin: "auto",
                        width: "70px",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog(false)}
                    />
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog != "" ? (
                        dialog.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 20px",
                                display: "inline-flex",
                              }}
                            >
                              <h3
                                onClick={() => {
                                  setProjectName(diag[1] + ", " + diag[2]);
                                  setProject(diag[0]);
                                  setOpenDialog(false);

                                  setIsLoading(true);
                                  axios
                                    .post(
                                      "https://www.bdc.et/bdc-api/get_settled_expense_list.php",
                                      {
                                        settleFor: settleFor,
                                        project: diag[0],
                                        resource: resource,
                                      }
                                    )
                                    .then((res) => setData(res.data))
                                    .catch((err) => alert(err))
                                    .finally(() => setIsLoading(false));
                                }}
                                style={{
                                  width: "100%",
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "normal",
                                  marginLeft: "5px",
                                }}
                              >
                                {diag[1] + ", " + diag[2]}
                              </h3>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {openDialog2 ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    width: "500px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <div style={{ display: "inline-flex", width: "100%" }}>
                      <h2 style={{ marginLeft: "20px", color: "gray" }}>
                        የግብአት ዝርዝር
                      </h2>
                      <input
                        type="button"
                        id="register"
                        value="Cancel"
                        className="btn1"
                        style={{
                          width: "70px",
                          alignSelf: "right",
                          float: "right",
                          margin: "auto",
                        }}
                        onClick={() => setOpenDialog2(false)}
                      />
                    </div>
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(240,240,240)",
                        marginTop: "0px",
                      }}
                    >
                      <input
                        type="text"
                        className="txt-input"
                        placeholder="ለመፈለግ"
                        style={{ marginLeft: "10px" }}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            axios
                              .post(
                                "https://www.bdc.et/bdc-api/get_resource_list.php",
                                {
                                  resType: resType,
                                  resCategory: resCategory,
                                }
                              )
                              .then((res) => {
                                setDialog2(res.data);
                              });
                          } else {
                            axios
                              .post(
                                "https://www.bdc.et/bdc-api/get_search_resource.php",
                                {
                                  searchTerm: e.target.value,
                                }
                              )
                              .then((res) => {
                                setDialog2(res.data);
                              });
                          }
                        }}
                      />
                      <div style={{ display: "inline-flex" }}>
                        <label
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            color: "gray",
                          }}
                        >
                          <select
                            name="type"
                            id="type"
                            style={{ width: "200px" }}
                            onChange={(e) => {
                              setResType(e.target.value);
                              axios
                                .post(
                                  "https://www.bdc.et/bdc-api/get_resource_list.php",
                                  {
                                    resType: e.target.value,
                                    resCategory: resCategory,
                                  }
                                )
                                .then((res) => {
                                  setDialog2(res.data);
                                });
                            }}
                            className="txt-input"
                          >
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Equip & Machine">
                              Equip & Machine
                            </option>
                            <option value="Cash">Cash</option>
                          </select>
                        </label>
                        {resType == "Material" && (
                          <label
                            style={{
                              fontSize: "14px",
                              color: "gray",
                              marginLeft: "10px",
                            }}
                          >
                            <select
                              name="type"
                              id="category"
                              onChange={(e) => {
                                setResCategory(e.target.value);
                                axios
                                  .post(
                                    "https://www.bdc.et/bdc-api/get_resource_list.php",
                                    {
                                      resType: resType,
                                      resCategory: e.target.value,
                                    }
                                  )
                                  .then((res) => {
                                    setDialog2(res.data);
                                  });
                              }}
                              className="txt-input"
                              style={{ width: "200px" }}
                            >
                              <option value="Civil">Civil</option>
                              <option value="Finishing">Finishing</option>
                              <option value="Electrical">Electrical</option>
                              <option value="Sanitary">Sanitary</option>
                              <option value="Stationary">Stationary</option>
                              <option value="Furniture">Furniture</option>
                            </select>
                          </label>
                        )}
                        <br />
                      </div>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          paddingLeft: "20px",
                        }}
                      >
                        {dialog2.length + " "}Records
                      </p>
                    </div>
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog2 != "" ? (
                        dialog2.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{ padding: "10px 20px", cursor: "pointer" }}
                            onClick={() => {
                              setResourceName(diag[1]);
                              setResource(diag[0]);
                              setOpenDialog2(false);

                              setIsLoading(true);
                              axios
                                .post(
                                  "https://www.bdc.et/bdc-api/get_settled_expense_list.php",
                                  {
                                    settleFor: settleFor,
                                    project: project,
                                    resource: diag[0],
                                  }
                                )
                                .then((res) => setData(res.data))
                                .catch((err) => alert(err))
                                .finally(() => setIsLoading(false));
                            }}
                          >
                            <h3 style={{ margin: 0, color: "gray" }}>
                              {diag[1]}
                            </h3>
                            <p style={{ margin: 0 }}>{diag[3]}</p>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {openDialog3 ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    width: "500px",
                  }}
                >
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      የሰራተኛ ዝርዝር
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        width: "70px",
                        alignSelf: "right",
                        margin: "20px auto",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog3(false)}
                    />
                  </div>

                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog3 != "" ? (
                        dialog3.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                              display: "inline-flex",
                            }}
                            key={diag[0]}
                          >
                            <FontAwesomeIcon
                              className="menu-icon"
                              id={"favStar"}
                              style={{
                                color: diag[18] == 1 ? "green" : "lightgray",
                                margin: "10px 5px 10px 10px",
                                cursor: "pointer",
                              }}
                              icon={faStar}
                            />
                            <p
                              style={{
                                margin: "10px 5px",
                                color: "black",
                                width: "100%",
                              }}
                              onClick={() => {
                                setSettleFor(diag[0]);
                                setEmployee(
                                  diag[1] + " " + diag[2] + " " + diag[3]
                                );
                                setOpenDialog3(false);

                                setIsLoading(true);
                                axios
                                  .post(
                                    "https://www.bdc.et/bdc-api/get_settled_expense_list.php",
                                    {
                                      settleFor: diag[0],
                                      project: project,
                                      resource: resource,
                                    }
                                  )
                                  .then((res) => setData(res.data))
                                  .catch((err) => alert(err))
                                  .finally(() => setIsLoading(false));
                              }}
                            >
                              {diag[1] + " " + diag[2] + " " + diag[3]}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "100%",
              minWidth: "600px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: "gray",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h2
                style={{
                  margin: "0",
                  color: "white",
                  borderColor: "white",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                የተላከከ ገንዘብ ዝርዝር
              </h2>
              <p style={{ color: "whitesmoke", marginLeft: "20px" }}>
                ቀን: {current_date}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "rgb(240,240,240)",
                display: "inline-flex",
              }}
            >
              <div
                style={{ width: "30%", marginRight: "10px", padding: "10px" }}
              >
                <p style={{ padding: 0, margin: 0 }}>{projectName}</p>
                <input
                  type="button"
                  className="btn2"
                  value={"ፕሮጀክት ለመምረጥ"}
                  onClick={OpenDialog}
                />
              </div>
              <div style={{ width: "30%", padding: "10px" }}>
                <p style={{ padding: 0, margin: 0 }}>{resourcName}</p>
                <input
                  type="button"
                  className="btn2"
                  value={"ግብአት ለመምረጥ"}
                  onClick={OpenDialog2}
                />
              </div>
              <div style={{ width: "30%", padding: "10px" }}>
                <p style={{ padding: 0, margin: 0 }}>{employee}</p>
                <input
                  type="button"
                  className="btn2"
                  value={"ሰራተኛ ለመምረጥ"}
                  onClick={OpenDialog3}
                />
              </div>
            </div>
            <p style={{ margin: "0 20px" }}>
              {"[ " + data.length + " Records ]"}
            </p>
            <table
              style={{
                width: "100%",
                minWidth: "600px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: "45px",
                }}
              >
                <th>ፕሮጀክት</th>
                <th>ግብአት</th>
                <th>መለክያ</th>
                <th>ነጠላ ዋጋ</th>
                <th>የተላከ መጠን</th>
                <th>ጠቅላላ ዋጋ</th>
                <th>የሰነድ ቁ.</th>
                <th>የተላከበት ቀን</th>
                <th></th>
                <th></th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data != [] &&
                    data.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[2]}</td>
                        <td> {list[3]}</td>
                        <td> {list[4]}</td>
                        <td> {parseFloat(list[5]).toLocaleString()}</td>
                        <td> {parseFloat(list[6]).toLocaleString()}</td>
                        <td>
                          {" "}
                          {(
                            parseFloat(list[6]) * parseFloat(list[5])
                          ).toLocaleString()}
                        </td>
                        <td> {list[7]}</td>
                        <td> {moment(list[10]).format("DD-MM-YYYY")}</td>
                        <td>
                          {list[13] == empId && (
                            <FontAwesomeIcon
                              className="menu-icon"
                              id="dlt-icon"
                              style={{
                                padding: "3px",
                                cursor: "pointer",
                                margin: "0",
                                padding: 0,
                                fontSize: "14px",
                                color: "gray",
                              }}
                              icon={faTrashCan}
                              onClick={() =>
                                DeleteReq(list[0], list[1], list[7])
                              }
                            />
                          )}
                        </td>

                        <td>
                          <input
                            style={{ cursor: "pointer" }}
                            type="button"
                            value="ክፈት"
                            onClick={() => {
                              navigate("/expense_settle_detail", {
                                state: {
                                  id: list[0],
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default ExpenseSettleList;
