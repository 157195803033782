import axios from "axios";
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loading1 from "../img/loading1.gif";

function AddPrivilege() {
  const [isLoading, setIsLoading] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const location = useLocation();
  const empId = location.state.id;
  const idNumber = location.state.idNumber;
  const fullName = location.state.fullName;
  const [tab11, setTab11] = useState(false);
  const [tab12, setTab12] = useState(false);
  const [tab21, setTab21] = useState(false);
  const [tab31, setTab31] = useState(false);
  const [tab32, setTab32] = useState(false);
  const [tab33, setTab33] = useState(false);
  const [tab41, setTab41] = useState(false);
  const [tab42, setTab42] = useState(false);
  const [tab51, setTab51] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "> Human Resource > Employee Role";
  }, []);

  const RegiBtn = () => {
    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/post_employee_role.php", {
        empId: empId,
        tab11: tab11,
        tab12: tab12,
        tab21: tab21,
        tab31: tab31,
        tab32: tab32,
        tab33: tab33,
        tab41: tab41,
        tab42: tab42,
        tab51: tab51,
      })
      .then(() => {
        navigate("/employee_detail", {
          state: {
            id: empId,
            idNumber: idNumber,
          },
        });
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {tab.includes("tab11") && (
        <div
          className="cont-all"
          style={{
            width: "90%",
            height: "95%",
            padding: "0",
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "gray",
              margin: "0",
              display: "inline-flex",
            }}
          >
            <h2
              style={{
                margin: "0",
                color: "white",
                borderColor: "white",
                borderRight: "solid",
                borderWidth: "1px",
                padding: "10px 20px",
              }}
            >
              Employee Role
            </h2>
            <p style={{ color: "white", marginLeft: "20px" }}>{fullName}</p>
          </div>
          <div
            style={{
              paddingLeft: "40px",
              width: "300px",
            }}
          >
            <h5 style={{ margin: 0, padding: 0, marginTop: "20px" }}>
              Default Role
            </h5>
            <select
              name="type"
              id="type"
              style={{ width: "250px" }}
              className="txt-input"
              onChange={(e) => {
                if (e.target.value == 1) {
                  setTab11(true);
                  setTab12(true);
                  setTab21(false);
                  setTab31(false);
                  setTab32(false);
                  setTab33(false);
                  setTab41(false);
                  setTab42(false);
                  setTab51(false);
                } else if (e.target.value == 2) {
                  setTab11(false);
                  setTab12(false);
                  setTab21(false);
                  setTab31(true);
                  setTab32(true);
                  setTab33(false);
                  setTab41(false);
                  setTab42(false);
                  setTab51(false);
                } else if (e.target.value == 3) {
                  setTab11(false);
                  setTab12(false);
                  setTab21(true);
                  setTab31(false);
                  setTab32(false);
                  setTab33(true);
                  setTab41(false);
                  setTab42(false);
                  setTab51(false);
                } else if (e.target.value == 4) {
                  setTab11(false);
                  setTab12(false);
                  setTab21(false);
                  setTab31(false);
                  setTab32(false);
                  setTab33(false);
                  setTab41(true);
                  setTab42(false);
                  setTab51(false);
                } else if (e.target.value == 5) {
                  setTab11(false);
                  setTab12(false);
                  setTab21(false);
                  setTab31(false);
                  setTab32(false);
                  setTab33(false);
                  setTab41(true);
                  setTab42(false);
                  setTab51(false);
                } else if (e.target.value == 6) {
                  setTab11(false);
                  setTab12(false);
                  setTab21(false);
                  setTab31(false);
                  setTab32(false);
                  setTab33(false);
                  setTab41(false);
                  setTab42(true);
                  setTab51(true);
                }else if (e.target.value == 7) {
                  setTab11(true);
                  setTab12(true);
                  setTab21(true);
                  setTab31(true);
                  setTab32(true);
                  setTab33(true);
                  setTab41(true);
                  setTab42(true);
                  setTab51(true);
                }
              }}
            >
              <option value="">
                Default Roles
              </option>
              <option value="1">Human Resource</option>
              <option value="2">Supervision Engineer</option>
              <option value="3">Contract Engineer</option>
              <option value="4">Purchase and Logistics</option>
              <option value="5">Machinery and Equipment</option>
              <option value="6">Finance</option>
              <option value="7">Check All</option>
            </select>
            <h5>Employee</h5>
            <label
              style={{
                padding: "3px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="list"
                checked={tab11 ? true : false}
                onClick={(e) => {
                  e.target.checked ? setTab11(true) : setTab11(false);
                }}
              />
              Add Employee
            </label>
            <br />
            <label
              style={{
                padding: "3px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="list"
                value={tab12}
                checked={tab12 ? true : false}
                onClick={(e) =>
                  e.target.checked ? setTab12(true) : setTab12(false)
                }
              />
              Employee List
            </label>
            <h5>Project</h5>
            <label
              style={{
                padding: "3px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="list"
                value={tab21}
                checked={tab21 == true ? true : false}
                onClick={(e) =>
                  e.target.checked ? setTab21(true) : setTab21(false)
                }
              />
              Create New Project
            </label>
            <h5>Resource</h5>
            <label
              style={{
                padding: "3px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="list"
                value={tab31}
                checked={tab31 == true ? true : false}
                onClick={(e) =>
                  e.target.checked ? setTab31(true) : setTab31(false)
                }
              />
              Add New Resource
            </label>
            <br />
            <label
              style={{
                padding: "3px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="list"
                value={tab32}
                checked={tab32 == true ? true : false}
                onClick={(e) =>
                  e.target.checked ? setTab32(true) : setTab32(false)
                }
              />
              Request Resource
            </label>
            <br />
            <label
              style={{
                padding: "3px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="list"
                value={tab33}
                checked={tab33 == true ? true : false}
                onClick={(e) =>
                  e.target.checked ? setTab33(true) : setTab33(false)
                }
              />
              Check Resource Request
            </label>
            <h5>Expense</h5>
            <label
              style={{
                padding: "3px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="list"
                value={tab41}
                checked={tab41 == true ? true : false}
                onClick={(e) =>
                  e.target.checked ? setTab41(true) : setTab41(false)
                }
              />
              Request Expense
            </label>
            <br />
            <label
              style={{
                padding: "3px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="list"
                value={tab42}
                checked={tab42 == true ? true : false}
                onClick={(e) =>
                  e.target.checked ? setTab42(true) : setTab42(false)
                }
              />
              Settle Expense
            </label>
            <h5>Report</h5>
            <label
              style={{
                padding: "3px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="list"
                value={tab51}
                checked={tab51 == true ? true : false}
                onClick={(e) =>
                  e.target.checked ? setTab51(true) : setTab51(false)
                }
              />
              Expense
            </label>
          </div>
          <div style={{ display: "inline-flex", marginBottom:"30px" }}>
            {isLoading ? (
              <div style={{ width: "200px", textAlign: "center" }}>
                <img
                  src={loading1}
                  alt="login..."
                  style={{ width: "80px", marginLeft: "40px" }}
                />
              </div>
            ) : (
              <input
                type="button"
                className="btn1"
                value="Save"
                style={{ width: "200px", marginLeft: "20px" }}
                onClick={RegiBtn}
              />
            )}
            <input
              type="button"
              className="btn2"
              value="Cancel"
              style={{ width: "200px", marginLeft: "20px" }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AddPrivilege;
