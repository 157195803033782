import axios from "axios";
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function EmpDashboardContract() {
  const navigate = useNavigate();
  const empId = useSelector((state) => state.auth.empId);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "> Dashboard";
    axios
      .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
        empId: empId,
      })
      .then((res) => setData(res.data[0]))
      .catch((err) => alert(err));

    axios
      .post("https://www.bdc.et/bdc-api/get_project_list_recent.php", {})
      .then((res) => setData2(res.data))
      .catch((err) => alert(err));
  }, []);

  const OpenDialog = () => {
    setOpenDialog(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
        empId: empId,
      })
      .then((res) => setDialog(res.data))
      .catch((err) => alert(err));
  };

  const ChangePassword = () => {
    document.getElementById("passChangeSuccess").style.display = "none";
    document.getElementById("oldPassErr").style.display = "none";
    document.getElementById("oldPassErr2").style.display = "none";
    document.getElementById("oldPass").style.borderColor = "lightgray";
    document.getElementById("newPassErr").style.display = "none";
    document.getElementById("newPass").style.borderColor = "lightgray";
    document.getElementById("confirmPassErr").style.display = "none";
    document.getElementById("confirmPassErr2").style.display = "none";
    document.getElementById("confirmPass").style.borderColor = "lightgray";

    if (oldPass == "") {
      document.getElementById("oldPassErr").style.display = "block";
      document.getElementById("oldPass").style.borderColor = "red";
    }
    if (newPass == "") {
      document.getElementById("newPassErr").style.display = "block";
      document.getElementById("newPass").style.borderColor = "red";
    }
    if (confirmPass == "") {
      document.getElementById("confirmPassErr").style.display = "block";
      document.getElementById("confirmPass").style.borderColor = "red";
    }
    if (confirmPass != newPass) {
      document.getElementById("confirmPassErr2").style.display = "block";
      document.getElementById("confirmPass").style.borderColor = "red";
    }

    if (
      oldPass != "" &&
      newPass != "" &&
      confirmPass != "" &&
      newPass == confirmPass
    ) {
      axios
        .post("https://www.bdc.et/bdc-api/post_edit_password.php", {
          empId: empId,
          oldPass: oldPass,
          newPass: newPass,
        })
        .then((res) => {
          if (res.data == 0) {
            document.getElementById("oldPassErr2").style.display = "block";
            document.getElementById("oldPass").style.borderColor = "red";
          } else {
            document.getElementById("oldPass").style.borderColor = "lightgray";
            document.getElementById("passChangeSuccess").style.display =
              "block";
          }
        });
    }
  };

  return (
    <>
      {openDialog ? (
        <>
          <div className="dialog-ext"></div>
          <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
            <div
              className="dialog-cont"
              style={{
                width: "500px",
                maxHeight: "600px",
                textAlign: "center",
              }}
            >
              <h2
                style={{ marginLeft: "20px", color: "gray", textAlign: "left" }}
              >
                Change password
              </h2>
              <p
                className="reg-err"
                id="passChangeSuccess"
                style={{ textAlign: "left", color: "green" }}
              >
                *Password changed successfully!
              </p>
              <p
                className="reg-err"
                id="oldPassErr"
                style={{ textAlign: "left" }}
              >
                *Required Information
              </p>
              <p
                className="reg-err"
                id="oldPassErr2"
                style={{ textAlign: "left" }}
              >
                *Wrong password
              </p>
              <input
                className="txt-input"
                id="oldPass"
                type={"password"}
                onChange={(e) => {
                  setOldPass(e.target.value);
                }}
                placeholder={"Enter old passowrd"}
                required
              />
              <p
                className="reg-err"
                id="newPassErr"
                style={{ textAlign: "left" }}
              >
                *Required Information
              </p>
              <input
                className="txt-input"
                id="newPass"
                type={"password"}
                onChange={(e) => {
                  setNewPass(e.target.value);
                }}
                placeholder={"Enter new passowrd"}
                required
              />
              <p
                className="reg-err"
                id="confirmPassErr"
                style={{ textAlign: "left" }}
              >
                *Required Information
              </p>
              <p
                className="reg-err"
                id="confirmPassErr2"
                style={{ textAlign: "left" }}
              >
                *Password don't match
              </p>
              <input
                className="txt-input"
                id="confirmPass"
                type={"password"}
                onChange={(e) => {
                  setConfirmPass(e.target.value);
                }}
                placeholder={"Confirm new passowrd"}
                required
              />
              <div style={{ textAlign: "left" }}>
                <input
                  type="button"
                  id="register"
                  value="Change"
                  className="btn1"
                  style={{
                    margin: "20px 40px",
                    width: "70px",
                    alignSelf: "right",
                  }}
                  onClick={ChangePassword}
                />
                <input
                  type="button"
                  id="register"
                  value="Cancel"
                  className="btn2"
                  style={{
                    margin: "20px 0",
                    width: "70px",
                    alignSelf: "right",
                  }}
                  onClick={() => setOpenDialog(false)}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div
        className="cont-all"
        style={{
          margin: 0,
          width: "100%",
          height: "100%",
          padding: "0",
          display: "inline-flex",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        <div style={{ width: "70%", height: "100%" }}>
          <div style={{ width: "100%", height: "10%" }}>
            <h2 style={{ margin: "20px 0px 0px 40px" }}>
              Welcome, {data != [] && data[1]}!
            </h2>
            <p style={{ margin: "0px 0px 0px 40px", color: "gray" }}>
              {data != [] && data[11]}
            </p>
          </div>
          <div
            style={{
              width: "100%",
              height: "20%",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                height: "90%",
                textAlign: "center",
                margin: "auto",
                float: "center",
                backgroundColor: "rgb(235, 235, 235)",
              }}
              className="brdr-all"
            >
              <FontAwesomeIcon
                className="menu-icon"
                style={{
                  color: "rgb(200,200,200)",
                  fontSize: "40px",
                  marginTop: "20px",
                }}
                icon={faBuilding}
              />
              <p
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/new_project", {});
                }}
              >
                Add new project
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "70%",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <div
              className="brdr-all"
              style={{
                width: "90%",
                height: "80%",
                float: "center",
                margin: "auto",
                backgroundColor: "rgb(235,235,235)",
              }}
            >
              <h4
                style={{
                  width: "100%",
                  paddingLeft: "20px",
                }}
              >
                Recently Added Projects
              </h4>
              <div
                style={{
                  display: "inline-flex",
                  backgroundColor: "white",
                  margin: "0px 20px 20px 20px",
                  padding: "10px 20px",
                }}
              >
                <h5
                  style={{
                    width: "300px",
                    color: "gray",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  Project
                </h5>
                <h5
                  style={{
                    width: "200px",
                    color: "gray",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  Location
                </h5>
                <h5
                  style={{
                    width: "100px",
                    color: "gray",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  Amount
                </h5>
              </div>
              {data2 != [] &&
                data2.map((list) => (
                  <div
                    style={{
                      display: "inline-flex",
                      margin: "0 20px",
                      padding: "10px 20px",
                      borderBottom: "solid",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                    key={list[0]}
                  >
                    <p
                      style={{
                        width: "300px",
                        margin: "0px",
                        padding: "0px",
                        fontSize: "14px",
                      }}
                    >
                      {list[1]}
                    </p>
                    <p
                      style={{
                        width: "200px",
                        margin: "0px",
                        padding: "0px",
                        fontSize: "14px",
                      }}
                    >
                      {list[2]}
                    </p>
                    <p
                      style={{
                        width: "100px",
                        margin: "0px",
                        padding: "0px",
                        fontSize: "14px",
                      }}
                    >
                      {parseFloat(list[5]).toLocaleString()}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "30%",
            height: "100%",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <div
            className="brdr-all"
            style={{
              width: "95%",
              height: "95%",
              float: "center",
              margin: "auto",
              backgroundColor: "rgb(235,235,235)",
            }}
          >
            <div style={{ width: "100%", height: "40%", textAlign: "center" }}>
              <div
                className="brdr-all"
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "60%",
                  margin: "20px auto",
                  float: "center",
                }}
              >
                <img
                  src={
                    "https://www.bdc.et/bdc-api/uploads/profile-pictures/" +
                    data[17]
                  }
                  alt="profile-picture"
                  style={{
                    width: "140px",
                    height: "140px",
                    borderRadius: "60%",
                    marginTop: "5px",
                    float: "center",
                  }}
                />
              </div>

              <h4 style={{ color: "gray" }}>
                {data != [] && data[1] + " " + data[2] + " " + data[3]}
              </h4>
              <p style={{ fontSize: "14px" }}>{data != [] && data[11]}</p>
            </div>
            <br />
            <div className="brdr-top" style={{ width: "100%" }}>
              <div
                style={{
                  display: "inline-flex",
                  padding: "10px 10px 10px 20px",
                  marginTop: "20px",
                }}
              >
                <h5 style={{ width: "100px", margin: 0, color: "gray" }}>
                  Phone
                </h5>
                <p style={{ fontSize: "14px", margin: 0 }}>
                  {data != [] && data[6]}
                </p>
              </div>
              <br />
              <div
                style={{
                  display: "inline-flex",
                  padding: "10px 10px 10px 20px",
                }}
              >
                <h5 style={{ width: "100px", margin: 0, color: "gray" }}>
                  Email
                </h5>
                <p style={{ fontSize: "14px", margin: 0 }}>
                  {" "}
                  {data != [] && data[7]}
                </p>
              </div>
              <br />
              <div
                style={{
                  display: "inline-flex",
                  padding: "10px 10px 10px 20px",
                }}
              >
                <h5 style={{ width: "100px", margin: 0, color: "gray" }}>
                  Department
                </h5>
                <p style={{ fontSize: "14px", margin: 0 }}>
                  {" "}
                  {data != [] && data[9]}
                </p>
              </div>
              <br />
              <div
                style={{
                  display: "inline-flex",
                  padding: "10px 10px 10px 20px",
                }}
              >
                <h5 style={{ width: "100px", margin: 0, color: "gray" }}>
                  Emp. status
                </h5>
                <p style={{ fontSize: "14px", margin: 0 }}>
                  {" "}
                  {data != [] && data[15]}
                </p>
              </div>
              <br />
              <div
                style={{
                  display: "inline-flex",
                  padding: "10px 10px 10px 20px",
                }}
              >
                <h5 style={{ width: "100px", margin: 0, color: "gray" }}>
                  Reg. on
                </h5>
                <p style={{ fontSize: "14px", margin: 0 }}>
                  {" "}
                  {data != [] && moment(data[13]).format("DD-MM-YYYY")}
                </p>
              </div>
              <br />
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "green",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={OpenDialog}
              >
                Setting
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmpDashboardContract;
