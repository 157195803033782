import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import moment from "moment";
import loading2 from "../img/loading2.gif";

function ResourceRequestDetail() {
  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const reqId = location.state.id;
  const [editWindow, setEditWindow] = useState(false);
  const [editId, setEditId] = useState("");
  const [editValue, setEditValue] = useState("");
  const [deleteWindow, setDeleteWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "> Resource > Request Detail";

    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_resource_request2.php", {
        req_id: reqId,
      })
      .then((res) => {
        setData(res.data[0]);

        axios
          .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
            empId: res.data[0][11],
          })
          .then((res2) => {
            setData2(res2.data[0]);
          })
          .catch((err) => alert(err));

        res.data[0][14] != 0 &&
          axios
            .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
              empId: res.data[0][14],
            })
            .then((res3) => {
              setData3(res3.data[0]);
            })
            .catch((err) => alert(err));
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  const EditValue = () => {
    if (editValue == "") {
      document.getElementById("editValueErr").style.display = "block";
      document.getElementById("editValue").style.borderColor = "red";
    } else {
      document.getElementById("editValueErr").style.display = "none";
      document.getElementById("editValue").style.borderColor = "lightgray";
    }

    if (editValue != "") {
      axios
        .post("https://www.bdc.et/bdc-api/post_edit_resource_request.php", {
          reqId: data[0],
          editId: editId,
          editValue: editValue,
        })
        .then((res) => {
          setData(res.data[0]);
          setEditWindow(false);

          res.data[0][14] != 0 &&
            axios
              .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
                empId: res.data[0][14],
              })
              .then((res3) => {
                setData3(res3.data[0]);
              })
              .catch((err) => alert(err));
        })
        .catch((err) => alert(err));
    }
  };

  const CheckReq = (state) => {
    if (state) {
      axios
        .post("https://www.bdc.et/bdc-api/post_resource_request_check.php", {
          reqId: reqId,
          empId: state,
        })
        .then((res) => {
          setData(res.data[0]);

          res.data[0][14] != 0 &&
            axios
              .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
                empId: res.data[0][14],
              })
              .then((res3) => {
                setData3(res3.data[0]);
              })
              .catch((err) => alert(err));
        })
        .catch((err) => alert(err));
    }
  };

  const DeleteValue = () => {
    axios
      .post("https://www.bdc.et/bdc-api/post_delete_resource_request.php", {
        id: data[0],
        docNumber: data[9],
      })
      .then(navigate("/requested_resource_list", {}))
      .catch((err) => alert(err));
  };

  return (
    <>
      {(tab.includes("tab32") ||
        tab.includes("tab33") ||
        tab.includes("tab41")) && (
        <>
          {deleteWindow ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{ marginTop: "60px", textAlign: "center" }}
                >
                  <p
                    id="deleteVerify"
                    style={{
                      margin: "10px ",
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                    }}
                  >
                    *Are you sure you want to delete this request? This action
                    cannot be undo!
                  </p>
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <input
                      type="button"
                      id="edit"
                      value="Delete"
                      className="btn1"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={DeleteValue}
                    />
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn2"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={() => setDeleteWindow(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {editWindow ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{ marginTop: "60px", textAlign: "center" }}
                >
                  <p
                    id="editValueErr"
                    style={{
                      margin: "10px 0 -10px 0",
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                      display: "none",
                    }}
                  >
                    *Required Information
                  </p>
                  <input
                    className="txt-input"
                    id="editValue"
                    type={"text"}
                    style={{ marginTop: "20px" }}
                    defaultValue={
                      editId == "Req Qty"
                        ? parseFloat(editValue)
                          ? parseFloat(editValue).toLocaleString()
                          : ""
                        : editValue
                    }
                    onChange={(e) => {
                      if (editId == "Req Qty") {
                        parseFloat(e.target.value)
                          ? setEditValue(parseFloat(e.target.value))
                          : setEditValue(0);
                      } else {
                        setEditValue(e.target.value);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("edit").click();
                      }
                    }}
                    onBlur={() => {
                      if (editId == "Req Qty") {
                        parseFloat(editValue)
                          ? (document.getElementById("editValue").value =
                              parseFloat(editValue).toLocaleString())
                          : (document.getElementById("editValue").value = "");
                      }
                    }}
                    onFocus={() =>
                      editId == "Req Qty" && editValue
                        ? (document.getElementById("editValue").value =
                            editValue.toString().replace(",", ""))
                        : ""
                    }
                    placeholder={"Enter " + editId}
                    required
                  />
                  <br />
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <input
                      type="button"
                      id="edit"
                      value="Edit"
                      className="btn1"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={EditValue}
                    />
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn2"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={() => setEditWindow(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "60%",
              minWidth: "500px",
              height: "97%",
              padding: "0",
              overflow: "auto",
            }}
          >
            <h3
              style={{
                backgroundColor: "gray",
                color: "white",
                margin: 0,
                padding: "5px 0px 5px 20px",
                position: "sticky",
                top: 0,
              }}
            >
              የተጠየቀ ግብአት መግለጫ
            </h3>
            {isLoading ? (
              <div style={{ width: "100%", textAlign: "center" }}>
                <img
                  src={loading2}
                  alt="loading..."
                  style={{ width: "50px", opacity: "0.3" }}
                />
              </div>
            ) : (
              <>
                {data != [] && (
                  <div style={{ marginLeft: "50px" }}>
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        ፕሮጀክት{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[2]}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        ግብአት{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[4]}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        መለክያ{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[5]}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        የተጠየቀ መጠን{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {parseFloat(data[6]).toLocaleString()}
                      </p>
                      {data[15] == 0 && data[11] == empId && (
                        <p
                          style={{
                            color: "green",
                            fontSize: "14px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEditId("Req Qty");
                            setEditValue(data[6]);
                            setEditWindow(true);
                          }}
                        >
                          ለመቀየር
                        </p>
                      )}
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        ምርመራ{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[8]}
                      </p>
                      {data[15] == 0 && data[11] == empId && (
                        <p
                          style={{
                            color: "green",
                            fontSize: "14px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEditId("Remark");
                            setEditValue(data[8]);
                            setEditWindow(true);
                          }}
                        >
                          ለመቀየር
                        </p>
                      )}
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        የግዥ መ.ቁ{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[9]}
                      </p>
                      {data[15] == 0 && data[11] == empId && (
                        <p
                          style={{
                            color: "green",
                            fontSize: "14px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEditId("Doc No");
                            setEditValue(data[9]);
                            setEditWindow(true);
                          }}
                        >
                          ለመቀየር
                        </p>
                      )}
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        የጠየቀው{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data2 &&
                          data2[1] + " " + " " + data2[2] + " " + data2[3]}
                      </p>
                    </div>
                    <br />
                    {data[14] != 0 && (
                      <>
                        <div
                          className="brdr-btm"
                          style={{
                            width: "100%",
                            display: "inline-flex",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "gray",
                              width: "160px",
                            }}
                          >
                            ያረጋገጠው{" "}
                          </p>
                          <p
                            style={{
                              fontWeight: "normal",
                              color: "black",
                            }}
                          >
                            {data3 &&
                              data3[1] + " " + " " + data3[2] + " " + data3[3]}
                          </p>
                        </div>
                        <br />
                      </>
                    )}
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        የተጠየቀበት ቀን{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {moment(data[10]).format("DD-MM-YYYY")}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        ያለበት ደረጃ{" "}
                      </p>
                      {data[7] > 0 ? (
                        <>
                          {data[14] == 0 ? (
                            <p style={{ fontWeight: "normal", color: "red" }}>
                              ያልተረጋገጠ
                            </p>
                          ) : (
                            <p style={{ fontWeight: "normal", color: "green" }}>
                              ተረጋግጧል
                            </p>
                          )}
                        </>
                      ) : (
                        <p style={{ fontWeight: "normal", color: "blue" }}>
                          የተጠናቀቀ
                        </p>
                      )}
                    </div>
                    <br />
                    <div style={{ display: "inline-flex" }}>
                      {tab.includes("tab33") && (
                        <>
                          {data[14] == 0 ? (
                            <input
                              type="button"
                              className="btn1"
                              style={{ width: "160px" }}
                              value={"አረጋግጥ"}
                              onClick={() => CheckReq(empId)}
                            />
                          ) : (
                            <>
                              {data[14] == empId && data[6] == data[7] ? (
                                <input
                                  type="button"
                                  className="btn1"
                                  style={{
                                    cursor: "pointer",
                                    color: "gray",
                                    backgroundColor: "lightgray",
                                  }}
                                  value={"ተረጋግጧል"}
                                  onClick={() => CheckReq("0")}
                                />
                              ) : (
                                <p
                                  style={{
                                    color: "green",
                                    width: "70px",
                                    margin: 0,
                                    textAlign: "center",
                                  }}
                                >
                                  ተረጋግጧል
                                </p>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {data[15] == 0 && data[11] == empId && (
                        <div
                          className="btn2"
                          style={{
                            width: "150px",
                            textAlign: "center",
                            justifyContent: "center",
                            display: "inline-flex",
                            marginLeft: "20px",
                          }}
                          onClick={() => setDeleteWindow(true)}
                        >
                          <FontAwesomeIcon
                            className="menu-icon"
                            id="dlt-icon"
                            style={{
                              padding: "3px",
                              cursor: "pointer",
                              margin: "0",
                              padding: 0,
                              fontSize: "14px",
                            }}
                            icon={faTrashCan}
                            onClick={() => {}}
                          />
                          <p
                            style={{
                              margin: "0",
                              padding: 0,
                              marginLeft: "10px",
                            }}
                          >
                            Delete
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ResourceRequestDetail;
