import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth_slice";
import ExpReqSlice from "./resourceSlice";
import ExpSettleSlice from "./expenseSlice";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, authSlice.reducer);
export const store = configureStore({
  reducer: {
    auth: persistedReducer,
    expReq: ExpReqSlice.reducer,
    expSettle: ExpSettleSlice.reducer,
  },
});

const persistor = persistStore(store);

export { persistor };
export default store;
