import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";


function ResourceRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const current_date = moment(Date()).format("DD-MM-YYYY");
  const loggedId = useSelector((state) => state.auth.empId);
  const [project, setProject] = useState("");
  const [projectId, setProjectId] = useState("");
  const [resource, setResource] = useState("");
  const [resourceId, setResourceId] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [unit, setUnit] = useState("");
  const [requestQty, setRequestQty] = useState("");
  const [remark, setRemark] = useState("");
  const [rv, setRv] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [resType, setResType] = useState("Material");
  const [resCategory, setResCategory] = useState("Civil");
  const [dialog, setDialog] = useState([]);
  const [dialog2, setDialog2] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/ግብአት /ግብአት ለመጠየቅ";
  }, []);

  const OpenDialog = () => {
    setOpenDialog(true);
    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => {
        setDialog(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const OpenDialog2 = () => {
    setOpenDialog2(true);
    setResType("Material");
    setResCategory("Civil");
    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_resource_list.php", {
        resType: "Material",
        resCategory: "Civil",
      })
      .then((res) => setDialog2(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const OpenRv = () => {
    if (rv != "") {
      setIsLoading3(true);
      axios
        .post("https://www.bdc.et/bdc-api/get_resource_request.php", {
          rv: rv,
        })
        .then((res) => setData(res.data))
        .catch((err) => alert(err))
        .finally(() => setIsLoading3(false));
    }
  };

  const RegiBtn = () => {
    if (rv == "") {
      document.getElementById("rv").style.borderColor = "red";
      document.getElementById("rvErr").style.display = "block";
    } else {
      document.getElementById("rv").style.borderColor = "lightgray";
      document.getElementById("rvErr").style.display = "none";
    }
    if (!requestQty) {
      document.getElementById("requestQty").style.borderColor = "red";
      document.getElementById("requestQtyErr").style.display = "block";
    } else {
      document.getElementById("requestQty").style.borderColor = "lightgray";
      document.getElementById("requestQtyErr").style.display = "none";
    }

    if (requestQty && rv != "") {
      setIsLoading(true);
      setIsLoading3(true);
      axios
        .post("https://www.bdc.et/bdc-api/post_resource_request.php", {
          project: project,
          projectId: projectId,
          resource: resource,
          resourceId: resourceId,
          resourceType: resType,
          resourceCategory: resCategory,
          unit: unit,
          requestQty: requestQty,
          remark: remark,
          rv: rv,
          reqBy: loggedId,
        })
        .then((res) => {
          setData(res.data);
          setResource("");
        })
        .catch((err) => alert(err))
        .finally(() => {
          setIsLoading(false);
          setIsLoading3(false);
        });
    }
  };

  const DeleteReq = (id, docNumber) => {
    axios
      .post("https://www.bdc.et/bdc-api/post_delete_resource_request.php", {
        id: id,
        docNumber: docNumber,
      })
      .then((res) => setData(res.data))
      .catch((err) => alert(err));
  };


  return (
    <>
      {tab.includes("tab32") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    width: "550px",
                  }}
                >
                  <div
                    className="brdr-btm"
                    style={{ display: "inline-flex", width: "100%" }}
                  >
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      የፕሮጀክት ዝርዝር
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        margin: "auto",
                        width: "70px",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog(false)}
                    />
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog != "" ? (
                        dialog.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 20px",
                                display: "inline-flex",
                              }}
                            >
                              
                              <h3
                                onClick={() => {
                                  setProject(diag[1] + ", " + diag[2]);
                                  setProjectId(diag[0]);
                                  setOpenDialog(false);
                                }}
                                style={{
                                  width: "100%",
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "normal",
                                  marginLeft: "5px",
                                }}
                              >
                                {diag[1] + ", " + diag[2]}
                              </h3>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}


          
          {openDialog2 ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    width: "500px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <div style={{ display: "inline-flex", width: "100%" }}>
                      <h2 style={{ marginLeft: "20px", color: "gray" }}>
                        የግብአት ዝርዝር
                      </h2>
                      <input
                        type="button"
                        id="register"
                        value="Cancel"
                        className="btn1"
                        style={{
                          width: "70px",
                          alignSelf: "right",
                          float: "right",
                          margin: "auto",
                        }}
                        onClick={() => setOpenDialog2(false)}
                      />
                    </div>
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(240,240,240)",
                        marginTop: "0px",
                      }}
                    >
                      <input
                        type="text"
                        className="txt-input"
                        placeholder="ለመፈለግ"
                        style={{ marginLeft: "10px" }}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            axios
                              .post(
                                "https://www.bdc.et/bdc-api/get_resource_list.php",
                                {
                                  resType: resType,
                                  resCategory: resCategory,
                                }
                              )
                              .then((res) => {
                                setDialog2(res.data);
                              });
                          } else {
                            axios
                              .post(
                                "https://www.bdc.et/bdc-api/get_search_resource.php",
                                {
                                  searchTerm: e.target.value,
                                }
                              )
                              .then((res) => {
                                setDialog2(res.data);
                              });
                          }
                        }}
                      />
                      <div style={{ display: "inline-flex" }}>
                        <label
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            color: "gray",
                          }}
                        >
                          <select
                            name="type"
                            id="type"
                            style={{ width: "200px" }}
                            onChange={(e) => {
                              setResType(e.target.value);
                              axios
                                .post(
                                  "https://www.bdc.et/bdc-api/get_resource_list.php",
                                  {
                                    resType: e.target.value,
                                    resCategory: resCategory,
                                  }
                                )
                                .then((res) => {
                                  setDialog2(res.data);
                                });
                            }}
                            className="txt-input"
                          >
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Equip & Machine">
                              Equip & Machine
                            </option>
                            <option value="Cash">Cash</option>
                          </select>
                        </label>
                        {resType == "Material" && (
                          <label
                            style={{
                              fontSize: "14px",
                              color: "gray",
                              marginLeft: "10px",
                            }}
                          >
                            <select
                              name="type"
                              id="category"
                              onChange={(e) => {
                                setResCategory(e.target.value);
                                axios
                                  .post(
                                    "https://www.bdc.et/bdc-api/get_resource_list.php",
                                    {
                                      resType: resType,
                                      resCategory: e.target.value,
                                    }
                                  )
                                  .then((res) => {
                                    setDialog2(res.data);
                                  });
                              }}
                              className="txt-input"
                              style={{ width: "200px" }}
                            >
                              <option value="Civil">Civil</option>
                              <option value="Finishing">Finishing</option>
                              <option value="Electrical">Electrical</option>
                              <option value="Sanitary">Sanitary</option>
                              <option value="Stationary">Stationary</option>
                              <option value="Furniture">Furniture</option>
                            </select>
                          </label>
                        )}
                        <br />
                      </div>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          paddingLeft: "20px",
                        }}
                      >
                        {dialog2.length + " "}Records
                      </p>
                    </div>
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog2 != "" ? (
                        dialog2.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{ padding: "10px 20px", cursor: "pointer" }}
                            onClick={() => {
                              setResource(diag[1]);
                              setResourceId(diag[0]);
                              setUnit(diag[3]);
                              setOpenDialog2(false);
                            }}
                          >
                            <h3 style={{ margin: 0, color: "gray" }}>
                              {diag[1]}
                            </h3>
                            <p style={{ margin: 0 }}>{diag[3]}</p>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          
          {/**Main container============================================================================= */}
          <div
            className="cont-all"
            style={{
              width: "100%",
              minWidth: "600px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: "gray",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h2
                style={{
                  margin: "0",
                  color: "white",
                  borderColor: "white",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                የግብአት መጠየቅያ ገፅ
              </h2>
              <p style={{ color: "whitesmoke", marginLeft: "20px" }}>
                ቀን: {current_date}
              </p>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                className="brdr-btm"
                style={{
                  width: "100%",
                  marginTop: "0",
                  paddingTop: "10px",
                  backgroundColor: "rgb(245,245,245)",
                  display: "inline-flex",
                }}
              >
                <label style={{ margin: "0px 20px 20px 20px", width: "30%" }}>
                  የግዥ መጠየቅያ ቁጥር
                  <br />
                  <div>
                    <p
                      id="rvErr"
                      style={{
                        color: "red",
                        display: "none",
                        fontSize: "14px",
                        margin: "5px 0px 0px 0px",
                      }}
                    >
                      *ይህ መረጃ አስፈላጊ ነው!
                    </p>
                    <input
                      className="txt-input"
                      id="rv"
                      type="text"
                      style={{ margin: 0 }}
                      onChange={(e) => {
                        setRv(e.target.value);
                      }}
                      onBlur={OpenRv}
                      placeholder="የግዥ መጠየቅያ ቁጥር ያስገቡ"
                    />
                  </div>
                </label>
                <div style={{ width: "30%", marginRight: "20px" }}>
                  <input
                    type="button"
                    id="register"
                    value={project == "" ? "ፕሮጀክት ለመምረጥ" : "ፕሮጀክት ለመቀየር"}
                    className="btn1"
                    style={{ margin: "17px 10px" }}
                    onClick={OpenDialog}
                  />
                </div>
                <div style={{ width: "30%" }}>
                  <input
                    type="button"
                    id="register"
                    value={resource == "" ? "ግብአት ለመምረጥ" : "ግብአት ለመቀየር"}
                    className="btn2"
                    style={{
                      margin: "17px 10px",
                      display: project ? "inline" : "none",
                    }}
                    onClick={OpenDialog2}
                  />
                </div>
              </div>
              <div
                className="brdr-btm"
                style={{
                  width: "100%",
                  display: project ? "block" : "none",
                  backgroundColor: "rgb(245,245,245)",
                }}
              >
                <label
                  style={{
                    padding: "10px",
                    display: "inline-flex",
                    fontWeight: "bold",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  ፕሮጀክት:
                  <p
                    style={{
                      margin: "0 10px",
                      padding: "0",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {project}
                  </p>
                </label>
                <br />
                {/**Resource container========================================================================================= */}
                <div
                  id="resourceCont"
                  style={{
                    width: "100%",
                    display: resource ? "inline-flex" : "none",
                  }}
                >
                  <label
                    style={{
                      margin: "10px 10px",
                      padding: "0",
                      display: "inline-flex",
                      fontWeight: "bold",
                      color: "gray",
                      fontSize: "14px",
                    }}
                  >
                    ግብአት:
                    <p
                      style={{
                        margin: "0 10px",
                        padding: "0",
                        fontWeight: "normal",
                        color: "black",
                      }}
                    >
                      {resource}
                    </p>
                  </label>
                  {/* sign */}
                  <label
                    style={{
                      margin: "10px 20px 0 0px",
                      padding: "0",
                      fontWeight: "bold",
                      color: "gray",
                      fontSize: "14px",
                      display:
                        resType == "Labor" || resType == "Cash"
                          ? "none"
                          : "inline-flex",
                    }}
                  >
                    መለክያ:
                    <p
                      style={{
                        margin: "0 10px",
                        padding: "0",
                        fontWeight: "normal",
                        color: "black",
                      }}
                    >
                      {unit}
                    </p>
                  </label>
                  <div>
                    <p
                      id="requestQtyErr"
                      style={{
                        color: "red",
                        display: "none",
                        fontSize: "14px",
                        margin: "0",
                      }}
                    >
                      *ይህ መረጃ አስፈላጊ ነው!
                    </p>
                    <input
                      className="txt-input"
                      id="requestQty"
                      type="number"
                      style={{ margin: "0 0 20px 0 " }}
                      onChange={(e) => {
                        setRequestQty(e.target.value);
                      }}
                      placeholder={
                        resType == "Labor" || resType == "Cash"
                          ? "የሚፈለግ መጠን"
                          : "የሚፈለግ መጠን"
                      }
                    />
                  </div>
                  <div>
                    <input
                      className="txt-input"
                      type="text"
                      style={{ margin: "0 0 0 20px" }}
                      onChange={(e) => {
                        setRemark(e.target.value);
                      }}
                      placeholder="ምርመራ (አማራጭ)"
                    />
                  </div>
                  {isLoading ? (
                    <>
                      <div style={{ marginLeft: "20px" }}>
                        <img
                          src={loading1}
                          alt="login..."
                          style={{ width: "80px" }}
                        />
                      </div>
                    </>
                  ) : (
                    <div style={{ width: "100px", marginLeft: "20px" }}>
                      <input
                        type="button"
                        id="register"
                        value="ጠይቅ"
                        className="btn1"
                        style={{ margin: "0 0 20px 20px" }}
                        onClick={RegiBtn}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/**Table container========================================================================================= */}

            <table
              style={{
                width: "100%",
                minWidth: "600px",
                marginTop: "20px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: "45px",
                }}
              >
                <th>ፕሮጀክት</th>
                <th>ግብአት</th>
                <th>መለክያ</th>
                <th>የተጠየቀ መጠን</th>
                <th>ምርመራ</th>
                <th>የግዥ መ.ቁ</th>
                <th>የተጠየቀበት ቀን</th>
                <th></th>
                <th></th>
              </tr>
              {isLoading3 ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data != [] &&
                    data.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[2]}</td>
                        <td> {list[4]}</td>
                        <td> {list[5]}</td>
                        <td> {parseFloat(list[6]).toLocaleString()}</td>
                        <td> {list[8]}</td>
                        <td> {list[9]}</td>
                        <td> {moment(list[10]).format("DD-MM-YYYY")}</td>
                        <td>
                          {list[11] == empId && (
                            <FontAwesomeIcon
                              className="menu-icon"
                              id="dlt-icon"
                              style={{
                                padding: "3px",
                                cursor: "pointer",
                                margin: "0",
                                padding: 0,
                                fontSize: "14px",
                                color: "gray",
                              }}
                              icon={faTrashCan}
                              onClick={() => DeleteReq(list[0], list[9])}
                            />
                          )}
                        </td>
                        <td>
                          <input
                            style={{ cursor: "pointer" }}
                            type="button"
                            value="ክፈት"
                            onClick={() => {
                              navigate("/resource_request_detail", {
                                state: {
                                  id: list[0],
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default ResourceRequest;
