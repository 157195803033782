import React, { useState, useEffect } from "react";
import logo from "../img/logo2.png";
import { useSelector } from "react-redux";

function Navbar() {
  const [windowTitle, setWindowTitle] = useState("");

  const department = useSelector((state) => state.auth.dep);

  return (
    <div className="nav-cont" style={{ display: "inline-flex" }}>
      <img src={logo} className="logo-pic" alt="logo" />
      <div style={{ display: "block" }}>
        <h1 style={{ color: "white", margin: "0", padding: "0" }}>
          ቢዲሲ ኮንስትራክሽን
        </h1>
        <div
          style={{
            margin: 0,
            padding: 0,
            display: "inline-flex",
            width: "100%",
          }}
        >
          
            <p
              style={{
                margin: "0 5px",
                padding: "0 10px",
                color: "white",
                borderStyle:"solid",
                borderColor:"white",
                borderWidth:"1px",
                borderRadius:"3px"
              }}
            >
             {department == "Construction Supervision" && ("የግንባታ ክትትል መምርያ" )}
             {department == "Contract Administration" && ("ኮንትራት አስተዳድር መምርያ" )}
             {department == "Purchase and Logistics" && ("የግዥና አቅርቦት መምርያ" )}
             {department == "Human Resource" && ("የሰው ሃይል መምርያ" )}
             {department == "Machinery and Equipment" && ("የማሽነሪና ተሽከርካሪ መምርያ" )}
             {department == "Finance" && ("የፋይናንስ መምርያ" )}
            </p>
         
          <p
            id="windowTitle"
            style={{ margin: 0, padding: 0, color: "white", color:"rgb(150,150,150)" }}
            onChange={(e) => {
              setWindowTitle(e.target.value);
            }}
          >
            {windowTitle}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
