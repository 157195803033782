import axios from "axios";
import { React } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function EmpConfirm() {
  const tab = useSelector((state) => state.auth.tab);
  const location = useLocation();
  const fullName = location.state.fullName;
  const empId = location.state.id;
  const idNumber = location.state.idNumber;
  const department = location.state.department;
  const navigate = useNavigate();

  return (
    <>
      {tab.includes("tab11") && (
        <div
          className="cont-all"
          style={{
            width: "500px",
            float: "center",
            margin: "auto",
            alignItems: "center",
            top: "100px",
            position: "relative",
          }}
        >
          <h1 style={{ color: "green", width: "100%", textAlign: "center" }}>
            Registered Successfully!
          </h1>
          <br />
          <h2 style={{ color: "gray", width: "100%", textAlign: "center" }}>
            {fullName}
          </h2>
          <input
            type="button"
            value="Add Role"
            className="btn1"
            onClick={() => {
              navigate("/add_privilege", {
                state: {
                  id: empId,
                  idNumber: idNumber,
                  department: department,
                  fullName: fullName,
                },
              });
            }}
            style={{ width: "200px", margin: "20px", justifyContent: "center" }}
          />
          <input
            type="button"
            value="Employee List"
            className="btn2"
            onClick={() => {
              navigate("/employee_registration");
            }}
            style={{ width: "200px", margin: "20px", justifyContent: "center" }}
          />
        </div>
      )}
    </>
  );
}

export default EmpConfirm;
