import { createSlice } from "@reduxjs/toolkit";

const ExpReqSlice = createSlice({
  name: "expReq",
  initialState: { docNumber: "", reqId: "" },
  reducers: {
    setDocNumber(state, action) {
      state.docNumber = action.payload.docNumber;
    },
    setReqId(state, action) {
      state.reqId = action.payload.reqId;
    },
  },
});

export const expReqAction = ExpReqSlice.actions;
export default ExpReqSlice;
