import axios from "axios";
import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faL,
  faPrint,
  faSearchMinus,
  faSearchPlus,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";
import logo from "../img/logo.png";
import ReactToPrint, { useReactToPrint } from "react-to-print";

function ExpenseSettleReport() {
  const printRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);

  const tab = useSelector((state) => state.auth.tab);
  const current_date = moment(Date()).format("MM-DD-YYYY");
  const docNumber2 = useSelector((state) => state.expSettle.docNumber);
  const reqId = useSelector((state) => state.expSettle.reqId);
  const employee2 = useSelector((state) => state.expSettle.settleFor);
  const [data, setData] = useState([]);
  const [materialExpense, setMaterialExpense] = useState(0);
  const [laborExpenese, setLaborExpense] = useState(0);
  const [equipExpense, setEquipExpense] = useState(0);
  const [otherExpense, setOtherExpense] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [resType, setResType] = useState("Material");
  const [resCategory, setResCategory] = useState("Civil");
  const [project, setProject] = useState("All");
  const [resource, setResource] = useState("All");
  const [settleFor, setSettleFor] = useState("All");
  const [settleForName, setSettleForName] = useState("All");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [dialog2, setDialog2] = useState([]);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [dialog3, setDialog3] = useState([]);
  const [regDate1, setRegDate1] = useState("");
  const [regDate2, setRegDate2] = useState("");
  const [openReport, setOpenReport] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/ሪፖርት /ወጭ";
  }, []);

  const OpenDialog = () => {
    setIsLoading2(true);
    setOpenDialog(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => {
        setDialog(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const OpenDialog2 = () => {
    setIsLoading2(true);
    setOpenDialog2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_resource_list.php", {
        resType: "Material",
        resCategory: "Civil",
      })
      .then((res) => {
        setDialog2(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const OpenDialog3 = () => {
    setIsLoading2(true);
    setOpenDialog3(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_employee_list.php", {})
      .then((res) => {
        setDialog3(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const HandlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Expense settlement report",
    onPrintError: (err) => alert(err),
  });

  const HandleOpenReport = () => {
    setIsLoading3(true);
    setOpenReport(true);

    axios
      .post("https://www.bdc.et/bdc-api/get_expense_settle_report.php", {
        project: project,
        resource: resource,
        settleFor: settleFor,
        regDate1: regDate1,
        regDate2: regDate2,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading3(false));
  };

  return (
    <>
      {tab.includes("tab51") && (
        <>
          {openReport ? (
            <div
              className="cont-all"
              style={{
                width: "100%",
                minWidth: "600px",
                height: "97%",
                overflow: "auto",
                backgroundColor: "rgb(200,200,200)",
                textAlign: "center",
              }}
            >
              <FontAwesomeIcon
                style={{
                  position: "sticky",
                  top: 0,
                  float: "left",
                  margin: "auto",
                  backgroundColor: "white",
                  fontSize: "40px",
                  padding: "10px",
                  borderRadius: "5px",
                  color: "green",
                  cursor: "pointer",
                }}
                icon={faPrint}
                onClick={HandlePrint}
                className="print-btn"
              />

              <FontAwesomeIcon
                style={{
                  position: "sticky",
                  top: 0,
                  float: "right",
                  margin: "auto",
                  backgroundColor: "white",
                  fontSize: "40px",
                  padding: "10px",
                  borderRadius: "5px",
                  color: "green",
                  cursor: "pointer",
                }}
                icon={faClose}
                className="print-btn"
                onClick={() => setOpenReport(false)}
              />
              <div
                style={{
                  margin: "20px auto",
                  width: "850px",
                  height: "1100px",
                  backgroundColor: "white",
                  boxShadow: "0px 5px 15px gray",
                  overflow: "auto",
                }}
              >
                <div ref={printRef} style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "130px",
                      display: "inline-flex",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        width: "20%",
                        marginLeft: "20px",
                        textAlign: "center",
                      }}
                    >
                      <img src={logo} style={{ width: "100px" }} alt="logo" />
                      <p
                        style={{
                          fontSize: "10px",
                          color: "green",
                          marginTop: "-30px",
                        }}
                      >
                        ቢዲሲ ኮንስትራክሽን
                      </p>
                    </div>

                    <h3
                      style={{
                        width: "80%",
                        textAlign: "left",
                        paddingLeft: "180px",
                        marginTop: "80px",
                      }}
                    >
                      የተላከ ገንዘብ ሪፖርት 
                    </h3>
                  </div>

                  <div
                    className="brdr-btm"
                    style={{
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{ display: "inline-flex", paddingLeft: "20px" }}
                    >
                      <h5
                        style={{
                          width: "100px",
                          margin: 0,
                          padding: 0,
                          color: "gray",
                        }}
                      >
                        ፕሮጀክት
                      </h5>
                      <p
                        className="brdr-btm"
                        style={{ fontSize: "14px", margin: 0, padding: 0 }}
                      >
                        {project}
                      </p>
                    </div>
                    <br />
                    <div
                      style={{ display: "inline-flex", paddingLeft: "20px" }}
                    >
                      <h5
                        style={{
                          width: "100px",
                          margin: 0,
                          padding: 0,
                          color: "gray",
                        }}
                      >
                        ግብአት
                      </h5>
                      <p
                        className="brdr-btm"
                        style={{ fontSize: "14px", margin: 0, padding: 0 }}
                      >
                        {resource}
                      </p>
                    </div>
                    <br />
                    <div
                      style={{ display: "inline-flex", paddingLeft: "20px" }}
                    >
                      <h5
                        style={{
                          width: "100px",
                          margin: 0,
                          padding: 0,
                          color: "gray",
                        }}
                      >
                        የተላከለት
                      </h5>
                      <p
                        className="brdr-btm"
                        style={{ fontSize: "14px", margin: 0, padding: 0 }}
                      >
                        {settleForName}
                      </p>
                    </div>
                    <br />
                    <div
                      style={{ display: "inline-flex", paddingLeft: "20px" }}
                    >
                      <h5
                        style={{
                          width: "100px",
                          margin: 0,
                          padding: 0,
                          color: "gray",
                        }}
                      >
                        የተላከበት ቀን
                      </h5>
                      <p style={{ fontSize: "14px", margin: 0, padding: 0 }}>
                        {regDate1 &&
                          !regDate2 &&
                          "> " + moment(regDate1).format("MM-DD-YYYY") + " GC"}
                      </p>
                      <p style={{ fontSize: "14px", margin: 0, padding: 0 }}>
                        {regDate1 &&
                          regDate2 &&
                          moment(regDate1).format("MM-DD-YYYY") +
                            " To " +
                            moment(regDate2).format("MM-DD-YYYY") +
                            " GC"}
                      </p>
                      <p style={{ fontSize: "14px", margin: 0, padding: 0 }}>
                        {!regDate1 &&
                          regDate2 &&
                          "< " + moment(regDate2).format("MM-DD-YYYY") + " GC"}
                      </p>
                      <p style={{ fontSize: "14px", margin: 0, padding: 0 }}>
                        {!regDate1 &&
                          !regDate2 &&
                          moment(current_date).format("MM-DD-YYYY") + " GC"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="brdr-btm"
                    style={{
                      width: "100%",
                      display: "inline-flex",
                      textAlign: "left",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      className="brdr-right"
                      style={{
                        paddingLeft: "20px",
                        width: "35%",
                      }}
                    >
                      <h4 style={{ width: "100%", margin: 0 }}>
                      የወጭ አይነት
                      </h4>
                      <div style={{ display: "inline-flex" }}>
                        <h5
                          style={{
                            width: "120px",
                            margin: 0,
                            padding: 0,
                            color: "gray",
                          }}
                        >
                          ግብአት
                        </h5>
                        <p
                          className="brdr-btm"
                          style={{ fontSize: "14px", margin: 0, padding: 0 }}
                        >
                          {data
                            .reduce((currnetTotal, list) => {
                              return list[12] == "Material"
                                ? currnetTotal + list[5] * list[6]
                                : 0;
                            }, 0)
                            .toLocaleString() + " Br"}
                        </p>
                      </div>
                      <br />
                      <div style={{ display: "inline-flex" }}>
                        <h5
                          style={{
                            width: "120px",
                            margin: 0,
                            padding: 0,
                            color: "gray",
                          }}
                        >
                          ሰራተኛ
                        </h5>
                        <p
                          className="brdr-btm"
                          style={{ fontSize: "14px", margin: 0, padding: 0 }}
                        >
                          {data
                            .reduce((currnetTotal, list) => {
                              return list[12] == "Labor"
                                ? currnetTotal + list[5] * list[6]
                                : 0;
                            }, 0)
                            .toLocaleString() + " Br"}
                        </p>
                      </div>
                      <br />
                      <div style={{ display: "inline-flex" }}>
                        <h5
                          style={{
                            width: "120px",
                            margin: 0,
                            padding: 0,
                            color: "gray",
                          }}
                        >
                          ማሽንና መሳርያ
                        </h5>
                        <p
                          className="brdr-btm"
                          style={{ fontSize: "14px", margin: 0, padding: 0 }}
                        >
                          {data
                            .reduce((currnetTotal, list) => {
                              return list[12] == "Equip & Machine"
                                ? currnetTotal + list[5] * list[6]
                                : 0;
                            }, 0)
                            .toLocaleString() + " Br"}
                        </p>
                      </div>
                      <br />
                      <div style={{ display: "inline-flex" }}>
                        <h5
                          style={{
                            width: "120px",
                            margin: 0,
                            padding: 0,
                            color: "gray",
                          }}
                        >
                          ሌሎች
                        </h5>
                        <p style={{ fontSize: "14px", margin: 0, padding: 0 }}>
                          {data
                            .reduce((currnetTotal, list) => {
                              return list[12] != "Material" &&
                                list[12] != "Labor" &&
                                list[12] != "Equip & Machine"
                                ? currnetTotal + list[5] * list[6]
                                : 0;
                            }, 0)
                            .toLocaleString() + " Br"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "65%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <h1
                        style={{
                          color: "gray",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        አጠቃላይ ወጭ{" "}
                        {data
                          .reduce((currnetTotal, list) => {
                            return currnetTotal + list[5] * list[6];
                          }, 0)
                          .toLocaleString() + " Br"}
                      </h1>
                    </div>
                  </div>
                  <table
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      textAlign: "left",
                    }}
                  >
                    <tr
                      style={{
                        width: "100%",
                        textAlign: "left",
                        color: "gray",
                        position: "sticky",
                        top: "129px",
                      }}
                    >
                      <th>ፕሮጀክት </th>
                      <th>ግብአት</th>
                      <th>መለክያ</th>
                      <th>ነጠላ ዋጋ + ታክስ</th>
                      <th>የተላከ መጠን</th>
                      <th>ጠቅላላ ዋጋ</th>
                      <th>የሰነድ ቁጥር</th>
                    </tr>
                    {data != [] &&
                      data.map((list) => (
                        <tr>
                          <td>{list[2]}</td>
                          <td>{list[3]}</td>
                          <td>{list[4]}</td>
                          <td>{parseFloat(list[5]).toLocaleString()}</td>
                          <td>{parseFloat(list[6]).toLocaleString()}</td>
                          <td>
                            {(
                              parseFloat(list[5]) * parseFloat(list[6])
                            ).toLocaleString()}
                          </td>
                          <td>{list[7]}</td>
                        </tr>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <>
              {openDialog ? (
                <>
                  <div className="dialog-ext"></div>
                  <div
                    style={{ width: "70%", zIndex: 2, position: "absolute" }}
                  >
                    <div
                      className="dialog-cont"
                      style={{
                        maxHeight: "600px",
                        overflowY: "auto",
                        width: "550px",
                      }}
                    >
                      <div
                        className="brdr-btm"
                        style={{ display: "inline-flex", width: "100%" }}
                      >
                        <h2 style={{ marginLeft: "20px", color: "gray" }}>
                          የፕሮጀክት ዝርዝር
                        </h2>
                        <input
                          type="button"
                          id="register"
                          value="Cancel"
                          className="btn1"
                          style={{
                            margin: "auto",
                            width: "70px",
                            float: "right",
                          }}
                          onClick={() => setOpenDialog(false)}
                        />
                      </div>
                      {isLoading2 ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <img
                            src={loading2}
                            alt="loading..."
                            style={{ width: "50px", opacity: "0.3" }}
                          />
                        </div>
                      ) : (
                        <>
                          {dialog != "" ? (
                            dialog.map((diag) => (
                              <div
                                className="brdr-btm list-hover"
                                key={diag[0]}
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "10px 20px",
                                    display: "inline-flex",
                                  }}
                                >
                                  <h3
                                    onClick={() => {
                                      setProject(diag[1] + ", " + diag[2]);
                                      setOpenDialog(false);
                                    }}
                                    style={{
                                      width: "100%",
                                      margin: 0,
                                      color: "black",
                                      fontWeight: "normal",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {diag[1] + ", " + diag[2]}
                                  </h3>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p style={{ marginLeft: "40px" }}>No record</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {openDialog2 ? (
                <>
                  <div className="dialog-ext"></div>
                  <div
                    style={{ width: "70%", zIndex: 2, position: "absolute" }}
                  >
                    <div
                      className="dialog-cont"
                      style={{
                        maxHeight: "600px",
                        overflowY: "auto",
                        width: "500px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <h2 style={{ marginLeft: "20px", color: "gray" }}>
                            የግብአት ዝርዝር
                          </h2>
                          <input
                            type="button"
                            id="register"
                            value="Cancel"
                            className="btn1"
                            style={{
                              width: "70px",
                              alignSelf: "right",
                              float: "right",
                              margin: "auto",
                            }}
                            onClick={() => setOpenDialog2(false)}
                          />
                        </div>
                        <div
                          className="brdr-btm"
                          style={{
                            width: "100%",
                            backgroundColor: "rgb(240,240,240)",
                            marginTop: "0px",
                          }}
                        >
                          <input
                            type="text"
                            className="txt-input"
                            placeholder="ለመፈለግ"
                            style={{ marginLeft: "10px" }}
                            onChange={(e) => {
                              if (e.target.value == "") {
                                axios
                                  .post(
                                    "https://www.bdc.et/bdc-api/get_resource_list.php",
                                    {
                                      resType: resType,
                                      resCategory: resCategory,
                                    }
                                  )
                                  .then((res) => {
                                    setDialog2(res.data);
                                  });
                              } else {
                                axios
                                  .post(
                                    "https://www.bdc.et/bdc-api/get_search_resource.php",
                                    {
                                      searchTerm: e.target.value,
                                    }
                                  )
                                  .then((res) => {
                                    setDialog2(res.data);
                                  });
                              }
                            }}
                          />
                          <div style={{ display: "inline-flex" }}>
                            <label
                              style={{
                                marginLeft: "10px",
                                fontSize: "14px",
                                color: "gray",
                              }}
                            >
                              <select
                                name="type"
                                id="type"
                                style={{ width: "200px" }}
                                onChange={(e) => {
                                  setResType(e.target.value);
                                  axios
                                    .post(
                                      "https://www.bdc.et/bdc-api/get_resource_list.php",
                                      {
                                        resType: e.target.value,
                                        resCategory: resCategory,
                                      }
                                    )
                                    .then((res) => {
                                      setDialog2(res.data);
                                    });
                                }}
                                className="txt-input"
                              >
                                <option value="Material">Material</option>
                                <option value="Labor">Labor</option>
                                <option value="Equip & Machine">
                                  Equip & Machine
                                </option>
                                <option value="Cash">Cash</option>
                              </select>
                            </label>
                            {resType == "Material" && (
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "gray",
                                  marginLeft: "10px",
                                }}
                              >
                                <select
                                  name="type"
                                  id="category"
                                  onChange={(e) => {
                                    setResCategory(e.target.value);
                                    axios
                                      .post(
                                        "https://www.bdc.et/bdc-api/get_resource_list.php",
                                        {
                                          resType: resType,
                                          resCategory: e.target.value,
                                        }
                                      )
                                      .then((res) => {
                                        setDialog2(res.data);
                                      });
                                  }}
                                  className="txt-input"
                                  style={{ width: "200px" }}
                                >
                                  <option value="Civil">Civil</option>
                                  <option value="Finishing">Finishing</option>
                                  <option value="Electrical">Electrical</option>
                                  <option value="Sanitary">Sanitary</option>
                                  <option value="Stationary">Stationary</option>
                                  <option value="Furniture">Furniture</option>
                                </select>
                              </label>
                            )}
                            <br />
                          </div>
                          <p
                            style={{
                              fontSize: "14px",
                              margin: "0",
                              paddingLeft: "20px",
                            }}
                          >
                            {dialog2.length + " "}Records
                          </p>
                        </div>
                      </div>
                      {isLoading2 ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <img
                            src={loading2}
                            alt="loading..."
                            style={{ width: "50px", opacity: "0.3" }}
                          />
                        </div>
                      ) : (
                        <>
                          {dialog2 != "" ? (
                            dialog2.map((diag) => (
                              <div
                                className="brdr-btm list-hover"
                                key={diag[0]}
                                style={{
                                  padding: "10px 20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setResource(diag[1]);
                                  setOpenDialog2(false);
                                }}
                              >
                                <h3 style={{ margin: 0, color: "gray" }}>
                                  {diag[1]}
                                </h3>
                                <p style={{ margin: 0 }}>{diag[3]}</p>
                              </div>
                            ))
                          ) : (
                            <p style={{ marginLeft: "40px" }}>No record</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {openDialog3 ? (
                <>
                  <div className="dialog-ext"></div>
                  <div
                    style={{ width: "70%", zIndex: 2, position: "absolute" }}
                  >
                    <div
                      className="dialog-cont"
                      style={{
                        maxHeight: "600px",
                        overflowY: "auto",
                        width: "500px",
                      }}
                    >
                      <div style={{ display: "inline-flex", width: "100%" }}>
                        <h2 style={{ marginLeft: "20px", color: "gray" }}>
                          የሰራተኛ ዝርዝር
                        </h2>
                        <input
                          type="button"
                          id="register"
                          value="Cancel"
                          className="btn1"
                          style={{
                            width: "70px",
                            alignSelf: "right",
                            margin: "20px auto",
                            float: "right",
                          }}
                          onClick={() => setOpenDialog3(false)}
                        />
                      </div>

                      {isLoading2 ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <img
                            src={loading2}
                            alt="loading..."
                            style={{ width: "50px", opacity: "0.3" }}
                          />
                        </div>
                      ) : (
                        <>
                          {dialog3 != "" ? (
                            dialog3.map((diag) => (
                              <div
                                className="brdr-btm list-hover"
                                style={{
                                  width: "100%",
                                  cursor: "pointer",
                                  display: "inline-flex",
                                }}
                                key={diag[0]}
                              >
                                <FontAwesomeIcon
                                  className="menu-icon"
                                  id={"favStar"}
                                  style={{
                                    color:
                                      diag[18] == 1 ? "green" : "lightgray",
                                    margin: "10px 5px 10px 10px",
                                    cursor: "pointer",
                                  }}
                                  icon={faStar}
                                />
                                <p
                                  style={{
                                    margin: "10px 5px",
                                    color: "black",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    setSettleFor(diag[0]);
                                    setSettleForName(
                                      diag[1] + " " + diag[2] + " " + diag[3]
                                    );
                                    setOpenDialog3(false);
                                  }}
                                >
                                  {diag[1] + " " + diag[2] + " " + diag[3]}
                                </p>
                              </div>
                            ))
                          ) : (
                            <p style={{ marginLeft: "40px" }}>No record</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div
                className="cont-all"
                style={{
                  width: "50%",
                  minWidth: "400px",
                  height: "97%",
                  paddingTop: "0",
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "45px",
                    backgroundColor: "gray",
                    margin: "0",
                    display: "inline-flex",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <h2
                    style={{
                      margin: "0",
                      color: "white",
                      borderColor: "white",
                      borderRight: "solid",
                      borderWidth: "1px",
                      padding: "10px 20px",
                    }}
                  >
                    የተላከ ገንዘብ ሪፖርት
                  </h2>
                  <p style={{ color: "whitesmoke", marginLeft: "20px" }}>
                    ቀን: {current_date}
                  </p>
                </div>
                <br />
                <div
                  id="resourceCont"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    paddingTop: "10px",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                      margin: "10px 20px 10px 10px",
                      padding: "20px 10px 10px 10px",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "-30px",
                        marginLeft: "15px",
                        fontSize: "14px",
                        backgroundColor: "white",
                        padding: "5px",
                        width: "85px",
                        color: "gray",
                      }}
                    >
                      Filter Report
                    </p>
                    <div style={{ marginLeft: "5px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          display: "inline-flex",
                        }}
                      >
                        <h4
                          style={{ width: "120px", color: "gray", margin: 0 }}
                        >
                          ፕሮጀክት
                        </h4>
                        <div>
                          {project}
                          <br />
                          <input
                            type="button"
                            id="register"
                            value={
                              project != "All" ? "ፕሮጀክት ለመቀየር" : "ፕሮጀክት ለመምረጥ"
                            }
                            className="btn2"
                            style={{ width: "200px", marginBottom: "20px" }}
                            onClick={OpenDialog}
                          />
                        </div>
                      </div>
                      <br />
                      <div
                        style={{
                          fontSize: "14px",
                          display: "inline-flex",
                        }}
                      >
                        <h4
                          style={{ width: "120px", color: "gray", margin: 0 }}
                        >
                          ግብአት
                        </h4>
                        <div>
                          {resource}
                          <br />
                          <input
                            type="button"
                            id="register"
                            value={
                              project != "All" ? "ግብአት ለመቀየር" : "ግብአት ለመምረጥ"
                            }
                            className="btn2"
                            style={{ width: "200px", marginBottom: "20px" }}
                            onClick={OpenDialog2}
                          />
                        </div>
                      </div>
                      <br />
                      <div
                        style={{
                          fontSize: "14px",
                          display: "inline-flex",
                        }}
                      >
                        <h4
                          style={{ width: "120px", color: "gray", margin: 0 }}
                        >
                          የተላከለት ሰራተኛ
                        </h4>
                        <div>
                          {settleForName}
                          <br />
                          <input
                            type="button"
                            id="register"
                            value={
                              project != "All" ? "ሰራተኛ ለመቀየር" : "ሰራተኛ ለመምረጥ"
                            }
                            className="btn2"
                            style={{ width: "200px", marginBottom: "20px" }}
                            onClick={OpenDialog3}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          display: "inline-flex",
                        }}
                      >
                        <h4
                          style={{ width: "120px", color: "gray", margin: 0 }}
                        >
                          የተላከበት ቀን
                        </h4>
                        <div>
                          {regDate1 == "" && regDate2 == "" && current_date}
                          {regDate1 != "" &&
                            regDate2 == "" &&
                            "> " + moment(regDate1).format("MM-DD-YYYY")}
                          {regDate1 == "" &&
                            regDate2 != "" &&
                            "< " + moment(regDate2).format("MM-DD-YYYY")}
                          {regDate1 != "" &&
                            regDate2 != "" &&
                            moment(regDate1).format("MM-DD-YYYY") +
                              " To " +
                              moment(regDate2).format("MM-DD-YYYY")}
                          <br />
                          <p
                            style={{
                              fontSize: "14px",
                              margin: "10px 0 0 0",
                              padding: 0,
                            }}
                          >
                            ከ
                          </p>

                          <input
                            type="date"
                            id="register"
                            className="txt-input"
                            style={{ width: "200px" }}
                            onChange={(e) => setRegDate1(e.target.value)}
                          />
                          <br />
                          <p
                            style={{
                              fontSize: "14px",
                              margin: "10px 0 0 0",
                              padding: 0,
                            }}
                          >
                            እስከ
                          </p>

                          <input
                            type="date"
                            id="register"
                            className="txt-input"
                            style={{ width: "200px" }}
                            onChange={(e) => setRegDate2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "inline-flex" }}>
                      {isLoading ? (
                        <>
                          <div style={{ marginLeft: "20px" }}>
                            <img
                              src={loading1}
                              alt="login..."
                              style={{ width: "80px" }}
                            />
                          </div>
                        </>
                      ) : (
                        <input
                          type="button"
                          id="register"
                          value="Open Report"
                          className="btn1"
                          style={{
                            width: "100px",
                            marginTop: "24px",
                            marginLeft: "125px",
                          }}
                          onClick={HandleOpenReport}
                        />
                      )}
                      <br />
                      {isLoading4 ? (
                        <>
                          <div style={{ marginLeft: "20px" }}>
                            <img
                              src={loading1}
                              alt="login..."
                              style={{ width: "80px" }}
                            />
                          </div>
                        </>
                      ) : (
                        <input
                          type="button"
                          id="register"
                          value="Reset"
                          className="btn2"
                          style={{
                            width: "100px",
                            marginTop: "24px",
                            marginLeft: "20px",
                          }}
                          onClick={() => {
                            setProject("All");
                            setResource("All");
                            setSettleFor("All");
                            setRegDate1("");
                            setRegDate2("");
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
export default ExpenseSettleReport;
