import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import loading2 from "../img/loading2.gif";

function StaffList() {
  const [isLoading, setIsLoading] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/የሰው ሃይል /የሰራተኛ ዝርዝር";
    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_employee_list.php", {})
      .then((res) => setData(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {tab.includes("tab11") && (
        <>
          <div
            className="cont-all"
            style={{
              width: "100%",
              minWidth: "800px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "gray",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top:0,
                height:"45px"
              }}
            >
              <h2
                style={{
                  margin: "0",
                  color: "white",
                  borderColor: "white",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                የሰራተኛ ዝርዝር
              </h2>
            </div>
            <p style={{margin:"0 20px"}}>{"[ "+data.length + " Records ]"}</p>
            <table
              style={{
                width: "100%",
                minWidth: "600px",
              }}
            >
              <tr style={{ width: "100%", textAlign: "left", color: "gray", position:"sticky", top:45 }}>
                <th>ሙሉ ስም</th>
                <th>ፆታ</th>
                <th>የመ. ቁጥር</th>
                <th>ስልክ ቁጥር</th>
                <th>መምርያ</th>
                <th>የስራ መደብ</th>
                <th></th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3"}}
                  />
                </div>
              ) : (
                <>
                  {data != [] &&
                    data.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[1] + " " + list[2] + " " + list[3]}</td>
                        <td> {list[4]}</td>
                        <td> {list[8]}</td>
                        <td> {list[6]}</td>
                        <td> {list[9]}</td>
                        <td> {list[11]}</td>
                        <td>
                          <input
                            style={{ cursor: "pointer" }}
                            type="button"
                            value="ክፈት"
                            onClick={() => {
                              navigate("/employee_detail", {
                                state: {
                                  id: list[0],
                                  idNumber: list[8],
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default StaffList;
