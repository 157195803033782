import { createSlice } from "@reduxjs/toolkit";

const ExpSettleSlice = createSlice({
  name: "expSettle",
  initialState: { docNumber: "", reqId: "", settleFor: "" },
  reducers: {
    setDocNumber(state, action) {
      state.docNumber = action.payload.docNumber;
    },
    setSettleFor(state, action) {
      state.settleFor = action.payload.settleFor;
    },
    setReqId(state, action) {
      state.reqId = action.payload.reqId;
    },
  },
});

export const expSettleAction = ExpSettleSlice.actions;
export default ExpSettleSlice;
