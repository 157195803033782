import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faPaperclip,
  faSignOut,
  faMoneyCheckDollar,
  faDashboard,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { authAction } from "./state/auth_slice";
import { useSelector } from "react-redux";
import axios from "axios";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import loading2 from "../img/loading2.gif";

function Sidemenu() {
  const [isLoading, setIsLoading] = useState(false);
  const fullName = useSelector((state) => state.auth.fullName);
  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(authAction.logout());
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
        empId: empId,
      })
      .then((res) => setData(res.data[0]))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="sideBar">
      <div
        style={{
          width: "220px",
          margin: 0,
          backgroundColor: "rgb(91, 91, 91)",
          height: "65%",
          overflowY: "auto",
          paddingLeft: "20px",
        }}
      >
        <>
          <Link className="side-title" to="/employee_dashboard">
            <FontAwesomeIcon
              className="menu-icon"
              style={{ color: "rgb(190,190,190)" }}
              icon={faDashboard}
            />
            <div className="side-title-cont">ዳሽ ቦርድ</div>
          </Link>
          <br />
        </>
        {(tab.includes("tab11") || tab.includes("tab12")) && (
          <>
            <Link className="side-title">
              <FontAwesomeIcon
                className="menu-icon"
                style={{ color: "rgb(190,190,190)" }}
                icon={faUsers}
              />
              <div className="side-title-cont2">የሰው ሃይል</div>
            </Link>
            <br />
          </>
        )}

        {tab.includes("tab11") && (
          <>
            <Link className="side-title2" to="/employee_registration">
              <div className="side-title-cont">ሰራተኛ ለመመዝገብ</div>
            </Link>
            <br />
          </>
        )}

        {tab.includes("tab12") && (
          <>
            <Link className="side-title2" to="/employee_list">
              <div className="side-title-cont">የሰራተኛ ዝርዝር</div>
            </Link>
            <br />
          </>
        )}

        {tab.includes("tab21") && (
          <>
            <Link className="side-title">
              <FontAwesomeIcon
                className="menu-icon"
                style={{ color: "rgb(190,190,190)" }}
                icon={faBuilding}
              />
              <div className="side-title-cont2">ፕሮጀክት</div>
            </Link>
            <br />
            <Link className="side-title2" to="/new_project">
              <div className="side-title-cont">ፕሮጀክት ለመመዝገብ</div>
            </Link>
            <br />
            <Link className="side-title2" to="/project_list">
              <div className="side-title-cont">የፕሮጀክት ዝርዝር</div>
            </Link>
          </>
        )}

        {(tab.includes("tab31") ||
          tab.includes("tab32") ||
          tab.includes("tab33") ||
          tab.includes("tab41")) && (
          <>
            <Link className="side-title">
              <FontAwesomeIcon
                className="menu-icon"
                style={{ color: "rgb(190,190,190)" }}
                icon={faArrowDown}
              />
              <div className="side-title-cont2">ግብአት</div>
            </Link>
            <br />
          </>
        )}

        {tab.includes("tab31") && (
          <>
            <Link className="side-title2" to="/new_resource">
              <div className="side-title-cont">ግብአት ለመመዝገብ</div>
            </Link>
            <br />
            <Link className="side-title2" to="/resource_list">
              <div className="side-title-cont">የግብአት ዝርዝር</div>
            </Link>
            <br />
          </>
        )}

        {tab.includes("tab32") && (
          <>
            <Link className="side-title2" to="/resource_request">
              <div className="side-title-cont">ግብአት ለመጠየቅ</div>
            </Link>
            <br />
          </>
        )}

        {(tab.includes("tab32") ||
          tab.includes("tab33") ||
          tab.includes("tab41")) && (
          <>
            <Link className="side-title2" to="/requested_resource_list">
              <div className="side-title-cont">የተጠየቀ ዝርዝር</div>
            </Link>
            <br />
          </>
        )}

        {(tab.includes("tab41") || tab.includes("tab42")) && (
          <>
            <Link className="side-title">
              <FontAwesomeIcon
                className="menu-icon"
                style={{ color: "rgb(190,190,190)" }}
                icon={faMoneyCheckDollar}
              />
              <div className="side-title-cont2">ወጭ</div>
            </Link>
            <br />
          </>
        )}

        {tab.includes("tab41") && (
          <>
            <Link className="side-title2" to="/request_for_expense">
              <div className="side-title-cont">ገንዘብ ለመጠየቅ</div>
            </Link>
          </>
        )}

        {(tab.includes("tab41") || tab.includes("tab42")) && (
          <>
            <Link className="side-title2" to="/requested_expense_list">
              <div className="side-title-cont">የተጠየቀ ዝርዝር</div>
            </Link>
            <br />
          </>
        )}

        {tab.includes("tab42") && (
          <>
            <Link className="side-title2" to="/settle_expense">
              <div className="side-title-cont">ገንዘብ ለመላክ</div>
            </Link>
            <br />
            <Link className="side-title2" to="/settled_expense_list">
              <div className="side-title-cont">የተላከ ዝርዝር</div>
            </Link>
            <br />
          </>
        )}

        {tab.includes("tab51") && (
          <>
            <Link className="side-title">
              <FontAwesomeIcon
                className="menu-icon"
                style={{ color: "rgb(190,190,190)" }}
                icon={faPaperclip}
              />
              <div className="side-title-cont2">ሪፖርት</div>
            </Link>
            <br />
          </>
        )}

        {tab.includes("tab51") && (
          <Link className="side-title2" to="/settled_expense_report">
            <div className="side-title-cont">ወጭ</div>
          </Link>
        )}
      </div>
      <hr
        style={{
          borderColor: "gray",
          marginLeft: "-20px",
          margin: 0,
          padding: "0",
        }}
      />
      <div
        style={{
          width: "220px",
          margin: 0,
          backgroundColor: "rgb(91, 91, 91)",
          height: "35%",
          overflowY: "auto",
          paddingLeft: "20px",
        }}
      >
        {isLoading ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              src={loading2}
              alt="loading..."
              style={{ width: "50px", opacity: "0.3" }}
            />
          </div>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                marginLeft: "-20px",
                textAlign: "center",
              }}
            >
              <img
                style={{ width: "70px", height: "70px", borderRadius: "50%" }}
                src={
                  "https://www.bdc.et/bdc-api/uploads/profile-pictures/" +
                  data[17]
                }
              />
            </div>
            <p style={{ color: "white" }}>{fullName}</p>
            <p
              style={{
                color: "rgb(200,200,200)",
                marginTop: "5px",
                fontSize: "14px",
              }}
            >
              {data && data[11]}
            </p>
            <Link className="side-title" onClick={logout}>
              <FontAwesomeIcon
                className="menu-icon"
                style={{ color: "rgb(190,190,190)", marginBottom: "10px" }}
                icon={faSignOut}
              />
              <div className="side-title-cont">ለመውጣት</div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default Sidemenu;
