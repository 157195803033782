import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

function ResourceDetail() {
  const tab = useSelector((state) => state.auth.tab);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const resId = location.state.id;
  const [editWindow, setEditWindow] = useState(false);
  const [editId, setEditId] = useState("");
  const [editValue, setEditValue] = useState("");
  const [deleteWindow, setDeleteWindow] = useState(false);

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/ግብአት /የግብአት መግለጫ";
    axios
      .post("https://www.bdc.et/bdc-api/get_resource_id.php", {
        resId: resId,
      })
      .then((res) => {
        setData(res.data[0]);
      });
  }, []);

  const EditValue = () => {
    if (editValue == "") {
      document.getElementById("editValueErr").style.display = "block";
      document.getElementById("editValue").style.borderColor = "red";
    } else {
      document.getElementById("editValueErr").style.display = "none";
      document.getElementById("editValue").style.borderColor = "lightgray";
    }

    if (editValue != "") {
      axios
        .post("https://www.bdc.et/bdc-api/post_edit_resource.php", {
          resId: data[0],
          editId: editId,
          editValue: editValue,
        })
        .then((res) => {
          setData(res.data[0]);
          setEditValue("");
          setEditWindow(false);
        });
    }
  };

  const DeleteValue = () => {
    if (data[0] != "") {
      axios
        .post("https://www.bdc.et/bdc-api/post_delete_resource.php", {
          resId: data[0],
        })
        .then(navigate("/resource_list", {}));
    }
  };

  return (
    <>
      {tab.includes("tab31") && (
        <>
          {deleteWindow ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{ marginTop: "60px", textAlign: "center" }}
                >
                  <p
                    id="deleteVerify"
                    style={{
                      margin: "10px ",
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                    }}
                  >
                    *Are you sure you want to delete this resource? You can't
                    undo this action!
                  </p>
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <input
                      type="button"
                      id="edit"
                      value="Delete"
                      className="btn1"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={DeleteValue}
                    />
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn2"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={() => setDeleteWindow(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {editWindow ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div className="dialog-cont" style={{ marginTop: "60px" }}>
                  <p
                    id="editValueErr"
                    style={{
                      margin: "10px 0 -10px 0",
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                      display: "none",
                    }}
                  >
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  {editId == "Resource Name" && (
                    <input
                      className="txt-input"
                      id="editValue"
                      type="text"
                      style={{ margin: "20px 0 0 20px", width: "400px" }}
                      defaultValue={editValue}
                      onChange={(e) => {
                        setEditValue(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          document.getElementById("edit").click();
                        }
                      }}
                      placeholder={"Enter " + editId}
                      required
                    />
                  )}
                  {editId == "Type" && (
                    <select
                      name="type"
                      id="editValue"
                      style={{ width: "400px", margin: "20px 0 0 20px" }}
                      onChange={(e) => {
                        setEditValue(e.target.value);
                      }}
                      className="txt-input"
                    >
                      <option style={{ color: "gray" }} value="">
                        Select Type
                      </option>
                      <option value="Material">Material</option>
                      <option value="Labor">Labor</option>
                      <option value="Equip & Machine">Equip & Machine</option>
                      <option value="Cash">Cash</option>
                    </select>
                  )}
                  {editId == "Category" && (
                    <select
                      name="type"
                      id="editValue"
                      onChange={(e) => setEditValue(e.target.value)}
                      className="txt-input"
                      style={{ width: "400px", margin: "20px 0 0 20px" }}
                    >
                      <option style={{ color: "gray" }} value="">
                        Select Category
                      </option>
                      <option value="Civil">Civil</option>
                      <option value="Finishing">Finishing</option>
                      <option value="Electrical">Electrical</option>
                      <option value="Sanitary">Sanitary</option>
                      <option value="Stationary">Stationary</option>
                      <option value="Furniture">Furniture</option>
                      <option value="Machinery">Machinery</option>
                    </select>
                  )}
                  {editId == "Unit" && (
                    <select
                      name="type"
                      id="editValue"
                      onChange={(e) => setEditValue(e.target.value)}
                      className="txt-input"
                      style={{ width: "400px", margin: "20px 0 0 20px" }}
                    >
                      <option style={{ color: "gray" }} value="">
                        Select Unit
                      </option>
                      <option value="ls">Lumpsum (ls)</option>
                      <option value="m3">Volume (m3)</option>
                      <option value="m2">Area (m2)</option>
                      <option value="ml">Meter Linear (ml)</option>
                      <option value="qtl">Weight (qtl)</option>
                      <option value="kg">Weight (kg)</option>
                      <option value="pcs">Number (pcs)</option>
                      <option value="pack">Pack (pack)</option>
                      <option value="lit">Volume (lit)</option>
                      <option value="gal">Volume (gal)</option>
                      <option value="hr">Time (hr)</option>
                    </select>
                  )}
                  <br />
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <input
                      type="button"
                      id="edit"
                      value="Edit"
                      className="btn1"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={EditValue}
                    />
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn2"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={() => setEditWindow(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "60%",
              minWidth: "500px",
              padding: "0",
              overflow: "auto",
            }}
          >
            <h3
              style={{
                backgroundColor: "gray",
                color: "white",
                margin: 0,
                padding: "5px 0px 5px 20px",
                position: "sticky",
                top: 0,
              }}
            >
              የግብአት መግለጫ
            </h3>
            {data != [] && (
              <div style={{ marginLeft: "50px" }}>
                <div style={{ display: "inline-flex" }}>
                  {" "}
                  <h2 style={{ color: "gray", marginLeft: "-20px" }}>
                    {data[1]}
                  </h2>{" "}
                  <FontAwesomeIcon
                    className="menu-icon brdr-all"
                    style={{
                      color: "green",
                      padding: "3px",
                      borderColor: "green",
                      cursor: "pointer",
                      fontSize: "10px",
                    }}
                    icon={faPen}
                    onClick={() => {
                      setEditId("Resource Name");
                      setEditWindow(true);
                    }}
                  />
                </div>
                <br />
                <div
                  className="brdr-btm"
                  style={{
                    display: "inline-flex",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      width: "160px",
                    }}
                  >
                    የግብአት አይነት{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data[2]}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "14px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditId("Type");
                      setEditWindow(true);
                    }}
                  >
                    edit
                  </p>
                </div>
                <br />

                {data[2] == "Material" && (
                  <>
                    <div
                      className="brdr-btm"
                      style={{
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        የግብአት ክፍል{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[5]}
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditId("Category");
                          setEditWindow(true);
                        }}
                      >
                        edit
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        መለክያ{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[3] ? data[3] : "-"}
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditId("Unit");
                          setEditWindow(true);
                        }}
                      >
                        edit
                      </p>
                    </div>
                  </>
                )}

                <br />
                <div style={{ display: "inline-flex" , marginBottom:"20px"}}>
                  <div
                    className="btn1"
                    style={{
                      width: "150px",
                      textAlign: "center",
                      justifyContent: "center",
                      display: "inline-flex",
                    }}
                    onClick={() => setDeleteWindow(true)}
                  >
                    <FontAwesomeIcon
                      className="menu-icon"
                      id="dlt-icon"
                      style={{
                        padding: "3px",
                        cursor: "pointer",
                        margin: "0",
                        padding: 0,
                        fontSize: "14px",
                      }}
                      icon={faTrashCan}
                      onClick={() => {}}
                    />
                    <p style={{ margin: "0", padding: 0, marginLeft: "10px" }}>
                      Delete
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ResourceDetail;
