import React from "react";
import { Routes, Route } from "react-router-dom";
import EmpRegistration from "./emp_registration";
import EmpConfirm from "./empConfirm";
import EmpDetail from "./empDetail";
import EmpDashboard from "./empDashboard";
import AddPrivilege from "./addPrivilege";
import NewProject from "./projectNew";
import NewResource from "./resourceNew";
import ResourceList from "./resourceList";
import ExpenseRequest from "./expenseRequest";
import ResourceRequest from "./resourceRequest";
import ResourceRequestList from "./resourceRequestList";
import ResourceRequestDetail from "./resourceRequestDetail";
import ExpenseSettle from "./expenseSettle";
import ExpenseRequestList from "./expenseRequestList";
import ExpenseSettleReport from "./expenseSettleReport";
import ProjectDetail from "./projectDetail";
import ResourceDetail from "./resourceDetail";
import StaffList from "./empList";
import ProjectList from "./projectList";
import ExpenseRequestDetail from "./expenseRequestDetail";
import ExpenseSettleDetail from "./expenseSettleDetail";
import ExpenseSettleList from "./expenseSettleList";

function MainContCont() {
  return (
    <div className="main-cont-cont">
      <Routes>
        <Route
          exact
          path="/employee_registration"
          element={<EmpRegistration />}
        />
        <Route exact path="/employee_confirmation" element={<EmpConfirm />} />
        <Route exact path="/employee_list" element={<StaffList />} />
        <Route exact path="/employee_detail" element={<EmpDetail />} />
        <Route exact path="/employee_dashboard" element={<EmpDashboard />} />
        <Route exact path="/add_privilege" element={<AddPrivilege />} />
        <Route exact path="/new_project" element={<NewProject />} />
        <Route exact path="/project_list" element={<ProjectList />} />
        <Route exact path="/new_resource" element={<NewResource />} />
        <Route exact path="/resource_list" element={<ResourceList />} />
        <Route exact path="/request_for_expense" element={<ExpenseRequest />} />
        <Route exact path="/resource_request" element={<ResourceRequest />} />
        <Route exact path="/resource_request_detail" element={<ResourceRequestDetail />} />
        <Route
          exact
          path="/requested_resource_list"
          element={<ResourceRequestList />}
        />
        <Route exact path="/expense_request_detail" element={<ExpenseRequestDetail />} />
        <Route
          exact
          path="/requested_expense_list"
          element={<ExpenseRequestList />}
        />
        <Route exact path="/settle_expense" element={<ExpenseSettle />} />
        <Route exact path="/expense_settle_detail" element={<ExpenseSettleDetail />} />
        <Route exact path="/settled_expense_list" element={<ExpenseSettleList />} />
        <Route
          exact
          path="/settled_expense_report"
          element={<ExpenseSettleReport />}
        />
        <Route exact path="/project_detail" element={<ProjectDetail />} />
        <Route exact path="/resource_detail" element={<ResourceDetail />} />
      </Routes>
    </div>
  );
}

export default MainContCont;
