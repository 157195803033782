import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import loading2 from "../img/loading2.gif";

function ResourceList() {
  const [isLoading, setIsLoading] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const dep = useSelector((state) => state.auth.dep);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [resCategory, setResCategory] = useState(
    dep == "Construction Supervision" ? "Civil" : ""
  );
  const [resType, setResType] = useState("Material");

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/ግብአት /የግብአት ዝርዝር";
    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_resource_list.php", {
        resType: resType,
        resCategory: resCategory,
      })
      .then((res) => setData(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {tab.includes("tab31") && (
        <>
          <div
            className="cont-all"
            style={{
              width: "100%",
              minWidth: "800px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "gray",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
                height: "45px",
              }}
            >
              <h2
                style={{
                  margin: "0",
                  color: "white",
                  borderColor: "white",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                የተመዘገቡ ግብአቶች ዝርዝር
              </h2>
            </div>
            <div
              className="brdr-btm"
              style={{
                width: "100%",
                backgroundColor: "rgb(240,240,240)",
                marginTop: "0px",
              }}
            >
              <div style={{ display: "inline-flex", width: "100%" }}>
                <input
                  type="text"
                  className="txt-input"
                  placeholder="ለመፈለግ"
                  style={{ marginLeft: "10px", width: "40%" }}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      axios
                        .post(
                          "https://www.bdc.et/bdc-api/get_resource_list.php",
                          {
                            resType: resType,
                            resCategory: resCategory,
                          }
                        )
                        .then((res) => {
                          setData(res.data);
                        });
                    } else {
                      axios
                        .post(
                          "https://www.bdc.et/bdc-api/get_search_resource.php",
                          {
                            searchTerm: e.target.value,
                          }
                        )
                        .then((res) => {
                          setData(res.data);
                        });
                    }
                  }}
                />
                <label
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    color: "gray",
                    width: "30%",
                  }}
                >
                  <select
                    name="type"
                    id="type"
                    onChange={(e) => {
                      setResType(e.target.value);
                      axios
                        .post(
                          "https://www.bdc.et/bdc-api/get_resource_list.php",
                          {
                            resType: e.target.value,
                            resCategory: resCategory,
                          }
                        )
                        .then((res) => {
                          setData(res.data);
                        });
                    }}
                    className="txt-input"
                  >
                    <option value="Material">Material</option>
                    <option value="Labor">Labor</option>
                    <option value="Equipment">Equipment</option>
                    <option value="Others">Others</option>
                  </select>
                </label>
                {dep == "Construction Supervision" && (
                  <label
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      marginLeft: "10px",
                      width: "30%",
                    }}
                  >
                    <select
                      name="type"
                      id="category"
                      onChange={(e) => {
                        setResCategory(e.target.value);
                        setIsLoading(true);
                        axios
                          .post(
                            "https://www.bdc.et/bdc-api/get_resource_list.php",
                            {
                              resType: resType,
                              resCategory: e.target.value,
                            }
                          )
                          .then((res) => {
                            setData(res.data);
                          })
                          .catch((err) => alert(err))
                          .finally(() => setIsLoading(false));
                      }}
                      className="txt-input"
                    >
                      <option value="Civil">Civil</option>
                      <option value="Finishing">Finishing</option>
                      <option value="Electrical">Electrical</option>
                      <option value="Sanitary">Sanitary</option>
                      <option value="Others">Others</option>
                    </select>
                  </label>
                )}
                <br />
              </div>
            </div>
            <p style={{ margin: "0 20px" }}>
              {"[ " + data.length + " Records ]"}
            </p>
            <table
              style={{
                width: "70%",
                minWidth: "600px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: 45,
                }}
              >
                <th>የግብአት ስም</th>
                <th>አይነት</th>
                <th>ክፍል</th>
                <th>መለክያ</th>
                <th></th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data != [] &&
                    data.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[1]}</td>
                        <td> {list[2]}</td>
                        <td> {list[5]}</td>
                        <td> {list[3]}</td>
                        <td>
                          <input
                            style={{ cursor: "pointer" }}
                            type="button"
                            value="ክፈት"
                            onClick={() => {
                              navigate("/resource_detail", {
                                state: {
                                  id: list[0],
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default ResourceList;
