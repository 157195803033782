import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { expSettleAction } from "./state/expenseSlice";
import { useSelector } from "react-redux";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleCheck,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function ExpenseSettle() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const loggedId = useSelector((state) => state.auth.empId);
  const current_date = moment(Date()).format("DD-MM-YYYY");
  const docNumber2 = useSelector((state) => state.expSettle.docNumber);
  const reqId = useSelector((state) => state.expSettle.reqId);
  const settleFor = useSelector((state) => state.expSettle.settleFor);
  const [data, setData] = useState([]);
  const [docNumber, setDocNumber] = useState(docNumber2);
  const [resType, setResType] = useState("Material");
  const [requestQty, setRequestQty] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [vat, setVat] = useState(0);
  const [tot, setTot] = useState(0);
  const [tax, setTax] = useState(0);
  const [pension, setPension] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [data2, setData2] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [remainQty, setRemainQty] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [employee, setEmployee] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/ወጭ /ገንዘብ ለመላክ";

    if (reqId) {
      axios
        .post("https://www.bdc.et/bdc-api/get_expense_request2.php", {
          req_id: reqId,
        })
        .then((res) => {
          setData(res.data);
          setRemainQty(res.data[0][11]);
          setUnitPrice(res.data[0][5]);
          setResType(res.data[0][12]);
        })
        .catch((err) => alert(err));
    }

    if (docNumber != "") {
      setIsLoading3(true);
      axios
        .post("https://www.bdc.et/bdc-api/get_expense_settle.php", {
          doc_number: docNumber,
        })
        .then((res) => setData2(res.data))
        .catch((err) => alert(err))
        .finally(() => setIsLoading3(false));
    }

    if (settleFor != "") {
      axios
        .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
          empId: settleFor,
        })
        .then((res) =>
          setEmployee(
            res.data[0][1] + " " + res.data[0][2] + " " + res.data[0][3]
          )
        )
        .catch((err) => alert(err));
    }
  }, []);

  const OpenDialog = () => {
    setIsLoading2(true);
    setOpenDialog(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_employee_list.php", {})
      .then((res) => setDialog(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const OpenDocNumber = () => {
    setIsLoading3(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_expense_settle.php", {
        doc_number: docNumber,
      })
      .then((res) => setData2(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading3(false));

    dispatch(
      expSettleAction.setDocNumber({
        docNumber: docNumber,
      })
    );
  };

  const CompareQty = () => {
    if (parseFloat(requestQty) > parseFloat(remainQty)) {
      document.getElementById("requestQty").style.borderColor = "red";
      document.getElementById("requestQtyErr").style.display = "block";
    } else {
      document.getElementById("requestQty").style.borderColor = "lightgray";
      document.getElementById("requestQtyErr").style.display = "none";
    }
  };

  const RegiBtn = () => {
    if (docNumber == "") {
      document.getElementById("docNumber").style.borderColor = "red";
      document.getElementById("docErr").style.display = "block";
    } else {
      document.getElementById("docNumber").style.borderColor = "lightgray";
      document.getElementById("docErr").style.display = "none";
    }
    if (requestQty == "") {
      document.getElementById("requestQty").style.borderColor = "red";
    } else {
      document.getElementById("requestQty").style.borderColor = "lightgray";
    }
    if (settleFor == "") {
      document.getElementById("employeeErr").style.display = "block";
    } else {
      document.getElementById("employeeErr").style.display = "none";
    }

    if (
      parseFloat(requestQty) != 0 &&
      parseFloat(requestQty) <= parseFloat(remainQty) &&
      docNumber != "" &&
      settleFor != ""
    ) {
      setIsLoading(true);
      setIsLoading3(true);

      axios
        .post("https://www.bdc.et/bdc-api/post_expense_settle.php", {
          reqId: reqId,
          proName: data[0][2],
          proId: data[0][13],
          resName: data[0][3],
          resId: data[0][14],
          resType: data[0][12],
          resUnit: data[0][4],
          unitPrice: data[0][5],
          requestQty: requestQty,
          docNumber: docNumber,
          reqDocNumber: data[0][7],
          rvNumber: data[0][10],
          settleFor: settleFor,
          remainQty: parseFloat(data[0][11]) - parseFloat(requestQty),
          vat: vat,
          tot: tot,
          withHold:
            parseFloat(totalPrice) > 10000 ? parseFloat(totalPrice) * 0.02 : 0,
          tax: tax,
          pension: pension,
          settledBy: loggedId,
        })
        .then((res) => {
          dispatch(
            expSettleAction.setReqId({
              reqId: "",
            })
          );

          setData2(res.data);

          document.getElementById("resourceCont").style.display = "none";

          document.getElementById("successCheck").style.display = "block";

          setTimeout(() => {
            document.getElementById("successCheck").style.display = "none";
          }, [3000]);
        })
        .catch((err) => alert(err))
        .finally(() => {
          setIsLoading(false);
          setIsLoading3(false);
        });
    }
  };

  const DeleteReq = (id, reqId, docNumber) => {
    if (reqId) {
      axios
        .post("https://www.bdc.et/bdc-api/post_delete_expense_settle.php", {
          id: id,
          reqId: reqId,
          docNumber: docNumber,
        })
        .then((res) => setData2(res.data))
        .catch((err) => alert(err));
    }
  };

  const FavCheck = (id, fav_state) => {
    if (fav_state == 1) {
      axios.post("https://www.bdc.et/bdc-api/post_fav_check.php", {
        id: id,
        favCheck: 2,
      });
      document.getElementById("favStar" + id).style.color = "green";
    } else {
      axios.post("https://www.bdc.et/bdc-api/post_fav_check.php", {
        id: id,
        favCheck: 1,
      });
      document.getElementById("favStar" + id).style.color = "lightgray";
    }
    if (document.getElementById("favStar" + id).style.color == "lightgray") {
      document.getElementById("favStar" + id).style.color = "green";
    } else {
      document.getElementById("favStar" + id).style.color = "lightgray";
    }
  };

  return (
    <>
      {tab.includes("tab42") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    width: "500px",
                  }}
                >
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      የሰራተኛ ዝርዝር
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        width: "70px",
                        alignSelf: "right",
                        margin: "20px auto",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog(false)}
                    />
                  </div>

                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog != "" ? (
                        dialog.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                              display: "inline-flex",
                            }}
                            key={diag[0]}
                          >
                            <FontAwesomeIcon
                              className="menu-icon"
                              id={"favStar" + diag[0]}
                              style={{
                                color: diag[18] == 1 ? "green" : "lightgray",
                                margin: "10px 5px 10px 10px",
                                cursor: "pointer",
                              }}
                              icon={faStar}
                              onClick={() => FavCheck(diag[0], diag[18])}
                            />
                            <p
                              style={{
                                margin: "10px 5px",
                                color: "black",
                                width: "100%",
                              }}
                              onClick={() => {
                                setEmployee(
                                  diag[1] + " " + diag[2] + " " + diag[3]
                                );
                                setOpenDialog(false);
                                dispatch(
                                  expSettleAction.setSettleFor({
                                    settleFor: diag[0],
                                  })
                                );
                                document.getElementById(
                                  "employeeErr"
                                ).style.display = "none";
                              }}
                            >
                              {diag[1] + " " + diag[2] + " " + diag[3]}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          
          {/**Main contaianer==================================================================================== */}
          <div
            className="cont-all"
            style={{
              width: "100%",
              minWidth: "500px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: "gray",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h2
                style={{
                  margin: "0",
                  color: "white",
                  borderColor: "white",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                ገንዘብ መላክያ ገፅ
              </h2>
              <p style={{ color: "whitesmoke", marginLeft: "20px" }}>
                ቀን: {current_date}
              </p>
              <div
                id="successCheck"
                style={{
                  position: "absolute",
                  top: "40px",
                  left: "45%",
                  backgroundColor: "white",
                  padding: "1px 5px",
                  color: "green",
                  display: "none",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ marginRight: "5px" }}
                />
                ተሳክቷል!
              </div>
            </div>
            <br />
            <div
              className="brdr-btm"
              style={{
                width: "100%",
                marginTop: "0",
                paddingTop: "10px",
                backgroundColor: "rgb(245,245,245)",
                display: "inline-flex",
              }}
            >
              <label
                style={{
                  margin: "20px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "gray",
                  width: "25%",
                }}
              >
                የሰነድ ቁጥር
                <br />
                <p
                  id="docErr"
                  style={{
                    fontSize: "14px",
                    color: "red",
                    display: "none",
                    marginBottom: "0",
                    fontWeight: "normal",
                  }}
                >
                  *ይህ መረጃ አስፈላጊ ነው!
                </p>
                <input
                  className="txt-input"
                  id="docNumber"
                  type="text"
                  defaultValue={docNumber2}
                  onChange={(e) => {
                    setDocNumber(e.target.value);
                  }}
                  onBlur={OpenDocNumber}
                  placeholder="የሰነድ ቁጥር ያስገቡ"
                />
              </label>
              <label
                style={{
                  margin: "20px",
                  fontSize: "14px",
                  marginLeft: "0px",
                  fontWeight: "bold",
                  color: "gray",
                  width: "25%",
                }}
              >
                የሚላክለት ሰራተኛ
                <br />
                <p
                  id="employeeErr"
                  style={{
                    fontSize: "14px",
                    color: "red",
                    display: "none",
                    marginBottom: "0",
                    fontWeight: "normal",
                  }}
                >
                  *ይህ መረጃ አስፈላጊ ነው!
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    display: employee != "" ? "block" : "none",
                    fontWeight: "normal",
                    color: "black",
                    marginBottom: "0px",
                  }}
                >
                  {employee}
                </p>
                <input
                  className="btn2"
                  id="settleFor"
                  type="button"
                  defaultValue={docNumber2}
                  onClick={OpenDialog}
                  value={employee ? "ሰራተኛ ለመቀየር" : "ሰራተኛ ለመምረጥ"}
                />
              </label>
              <label
                style={{
                  margin: "20px",
                  fontSize: "14px",
                  marginLeft: "0",
                  fontWeight: "bold",
                  color: "gray",
                  width: "25%",
                }}
              >
                ጠቅላላ የወጭ መጠን <br />
                <input
                  className="txt-input"
                  id="totalCost"
                  type="text"
                  placeholder="ጠቅላላ የወጭ መጠን (ብር)"
                  value={
                    data2
                      .reduce((currnetTotal, list) => {
                        return (
                          currnetTotal +
                          (parseFloat(list[5]) +
                            parseFloat(list[17]) +
                            parseFloat(list[16])) *
                            parseFloat(list[6])
                        );
                      }, 0)
                      .toLocaleString() + " ብር"
                  }
                  disabled
                />
              </label>
              <input
                type="button"
                id="register"
                value={reqId == "" ? "የገንዘብ መጠየቅያ ለመምረጥ" : "የገንዘብ መጠየቅያ ለመቀየር"}
                className="btn1"
                style={{ width: "25%", height: "37px", marginTop: "41px" }}
                onClick={() => navigate("/requested_expense_list", {})}
              />
            </div>
            <div
              className="brdr-btm"
              id="resourceCont"
              style={{
                width: "100%",
                display: reqId ? "block" : "none",
                backgroundColor: "rgb(245,245,245)",
              }}
            >
              <label
                style={{
                  maxWidth: "350px",
                  margin: "20px 20px 10px 20px",
                  padding: "0",
                  display: "inline-flex",
                  fontWeight: "bold",
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                ፕሮጀክት:
                <p
                  style={{
                    margin: "0 10px",
                    padding: "0",
                    fontWeight: "normal",
                    color: "black",
                  }}
                >
                  {data != "" ? data[0][2] : ""}
                </p>
              </label>
              <label
                style={{
                  maxWidth: "350px",
                  margin: "0 20px",
                  padding: "0",
                  display: "inline-flex",
                  fontWeight: "bold",
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                ግብአት:
                <p
                  style={{
                    margin: "0 10px",
                    padding: "0",
                    fontWeight: "normal",
                    color: "black",
                  }}
                >
                  {data != "" ? data[0][3] : ""}
                </p>
              </label>
              {resType == "Material" || resType == "Equip & Machine" ? (
                <label
                  style={{
                    maxWidth: "50px",
                    margin: "10px",
                    marginRight: "40px",
                    padding: "0",
                    display: "inline-flex",
                    fontWeight: "bold",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  መለክያ:
                  <p
                    style={{
                      margin: "0 10px",
                      padding: "0",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data != "" ? data[0][4] : ""}
                  </p>
                </label>
              ) : (
                ""
              )}

              <label
                style={{
                  maxWidth: "180px",
                  margin: "10px",
                  marginRight: "20px",
                  padding: "0",
                  display: "inline-flex",
                  fontWeight: "bold",
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                {resType == "Material" || resType == "Equip & Machine"
                  ? "ያልተላከ መጠን:"
                  : "ያልተላከ መጠን:"}
                <p
                  style={{
                    margin: "0 10px",
                    padding: "0",
                    fontWeight: "normal",
                    color: "black",
                  }}
                >
                  {data != "" ? parseFloat(remainQty).toLocaleString() : ""}
                </p>
              </label>
              <br />
              <div
                style={{
                  display: reqId ? "inline-flex" : "none",
                  width: "100%",
                }}
              >
                {resType == "Material" || resType == "Equip & Machine" ? (
                  <>
                    {/* Material container ========================================================================================= */}
                    <div
                      style={{
                        display: "block",
                        width: "25%",
                      }}
                    >
                      <p
                        id="requestQtyErr"
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "red",
                          fontSize: "14px",
                          paddingLeft: "20px",
                          display: "none",
                        }}
                      >
                        *ከቀሪ መጠን በላይ!
                      </p>
                      <input
                        className="txt-input"
                        id="requestQty"
                        type="number"
                        style={{
                          minWidth: "180px",
                          marginLeft: "10px",
                          width: "80%",
                        }}
                        onChange={(e) => {
                          setRequestQty(
                            e.target.value == "" ? 0 : e.target.value
                          );
                          setTotalPrice(
                            parseFloat(
                              e.target.value == "" ? 0 : e.target.value
                            ) *
                              parseFloat(
                                parseFloat(unitPrice) +
                                  parseFloat(vat) +
                                  parseFloat(tot)
                              )
                          );
                        }}
                        onBlur={CompareQty}
                        placeholder="የሚላክ መጠን ያስገቡ"
                      />
                    </div>
                    <div
                      style={{
                        display: "block",
                        width: "25%",
                      }}
                    >
                      <div style={{ display: "inline-flex" }}>
                        <div
                          style={{
                            width: "90px",
                            fontSize: "14px",
                            padding: "10px",
                          }}
                        >
                          ነጠላ ዋጋ
                        </div>
                        <input
                          className="txt-input"
                          id="unitPrice"
                          type="text"
                          style={{ width: "150px" }}
                          value={parseFloat(unitPrice).toLocaleString() + " ብር"}
                          defaultValue={0}
                          disabled
                        />
                      </div>
                      <br />
                      <div style={{ display: "inline-flex" }}>
                        <div
                          style={{
                            width: "90px",
                            fontSize: "14px",
                            padding: "10px",
                          }}
                        >
                          ቫት 15%
                          <input
                            type="checkbox"
                            style={{ margin: "5px", cursor: "pointer" }}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setVat(parseFloat(unitPrice) * 0.15);
                                document.getElementById("vat").value =
                                  parseFloat(unitPrice) *
                                    (0.15).toLocaleString() +
                                  " ብር";

                                setTotalPrice(
                                  parseFloat(requestQty) *
                                    parseFloat(
                                      parseFloat(unitPrice) +
                                        parseFloat(
                                          parseFloat(unitPrice) * 0.15
                                        ) +
                                        parseFloat(tot)
                                    )
                                );
                              } else {
                                setVat(0);
                                document.getElementById("vat").value =
                                  0 + " ብር";

                                setTotalPrice(
                                  parseFloat(requestQty) *
                                    parseFloat(
                                      parseFloat(unitPrice) + parseFloat(tot)
                                    )
                                );
                              }
                            }}
                          />
                        </div>
                        <input
                          className="txt-input"
                          id="vat"
                          type="text"
                          style={{ width: "150px" }}
                          placeholder="Vat"
                          defaultValue={0 + " ብር"}
                          disabled
                        />
                      </div>
                      <br />
                      <div
                        style={{
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: "90px",
                            fontSize: "14px",
                            padding: "10px",
                          }}
                        >
                          ቲኦቲ 2%
                          <input
                            type="checkbox"
                            style={{ margin: "0 5px", cursor: "pointer" }}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setTot(parseFloat(unitPrice) * 0.02);
                                document.getElementById("tot").value =
                                  parseFloat(unitPrice) *
                                    (0.02).toLocaleString() +
                                  " ብር";

                                setTotalPrice(
                                  parseFloat(requestQty) *
                                    parseFloat(
                                      parseFloat(unitPrice) +
                                        parseFloat(vat) +
                                        parseFloat(unitPrice) * 0.02
                                    )
                                );
                              } else {
                                setTot(0);
                                document.getElementById("tot").value =
                                  0 + " ብር";

                                setTotalPrice(
                                  parseFloat(requestQty) *
                                    parseFloat(
                                      parseFloat(unitPrice) + parseFloat(vat)
                                    )
                                );
                              }
                            }}
                          />
                        </div>
                        <input
                          className="txt-input"
                          id="tot"
                          type="text"
                          style={{ width: "150px" }}
                          defaultValue={0 + " ብር"}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "block",
                        width: "25%",
                      }}
                    >
                      <div style={{ display: "inline-flex" }}>
                        <div
                          style={{
                            width: "90px",
                            fontSize: "14px",
                            padding: "10px",
                          }}
                        >
                          ጠቅላላ ዋጋ
                        </div>
                        <input
                          className="txt-input"
                          id="totalPrice"
                          type="text"
                          style={{ width: "150px" }}
                          value={
                            parseFloat(totalPrice).toLocaleString() + " ብር"
                          }
                          defaultValue={0}
                          disabled
                        />
                      </div>
                      <br />
                      <div style={{ display: "inline-flex" }}>
                        <div
                          style={{
                            width: "90px",
                            fontSize: "14px",
                            padding: "10px",
                          }}
                        >
                          ዊዝሆልድ 2%
                        </div>
                        <input
                          className="txt-input"
                          id="unitPrice"
                          type="text"
                          style={{ width: "150px" }}
                          onChange={() => {}}
                          value={
                            parseFloat(totalPrice) > 10000
                              ? (
                                  parseFloat(totalPrice) * 0.02
                                ).toLocaleString() + " ብር"
                              : "0 ብር"
                          }
                          defaultValue={0}
                          disabled
                        />
                      </div>
                      <br />
                      <label style={{ display: "inline-flex" }}>
                        <div
                          style={{
                            width: "90px",
                            fontSize: "14px",
                            padding: "10px",
                          }}
                        >
                          ተከፋይ
                        </div>
                        <input
                          className="txt-input"
                          id="unitPrice"
                          type="text"
                          style={{ width: "150px" }}
                          value={
                            parseFloat(
                              parseFloat(totalPrice) -
                                parseFloat(
                                  totalPrice > 10000
                                    ? parseFloat(totalPrice) * 0.02
                                    : 0
                                )
                            ).toLocaleString() + " ብር"
                          }
                          defaultValue={0}
                          disabled
                        />
                      </label>
                    </div>
                    {isLoading ? (
                      <>
                        <div style={{ marginLeft: "20px" }}>
                          <img
                            src={loading1}
                            alt="login..."
                            style={{ width: "80px" }}
                          />
                        </div>
                      </>
                    ) : (
                      <div style={{ width: "25%" }}>
                        <input
                          type="button"
                          id="register"
                          value="ላክ"
                          className="btn1"
                          style={{ margin: "5px 10px", width: "100px" }}
                          onClick={RegiBtn}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {/**labor table ==================================================================================== */}
                    <div style={{ display: "block" }}>
                      <p
                        id="requestQtyErr"
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "red",
                          fontSize: "14px",
                          paddingLeft: "20px",
                          display: "none",
                        }}
                      >
                        *Over Amount
                      </p>

                      <input
                        className="txt-input"
                        id="requestQty"
                        type="number"
                        style={{ width: "180px", marginLeft: "20px" }}
                        onChange={(e) => {
                          setRequestQty(
                            e.target.value == "" ? 0 : e.target.value
                          );
                          setTotalPrice(
                            parseFloat(
                              e.target.value == "" ? 0 : e.target.value
                            ) +
                              parseFloat(tax) +
                              parseFloat(pension)
                          );
                        }}
                        onBlur={CompareQty}
                        placeholder="Enter Settlement Amount"
                      />
                    </div>
                    <div
                      style={{
                        display: "block",
                        width: "300px",
                        marginLeft: "20px",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "14px",
                          verticalAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        Tax
                        <input
                          className="txt-input"
                          id="tax"
                          type="number"
                          style={{ width: "150px", marginLeft: "48px" }}
                          placeholder="Enter Tax (Optional)"
                          onChange={(e) => {
                            setTax(e.target.value == "" ? 0 : e.target.value);
                            setTotalPrice(
                              parseFloat(requestQty) +
                                parseFloat(
                                  e.target.value == "" ? 0 : e.target.value
                                ) +
                                parseFloat(pension)
                            );
                          }}
                        />
                      </label>

                      <br />
                      <label
                        style={{
                          fontSize: "14px",
                          verticalAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        Pension
                        <input
                          className="txt-input"
                          id="pension"
                          type="number"
                          style={{ width: "150px", marginLeft: "20px" }}
                          placeholder="Enter Pension (Optional)"
                          onChange={(e) => {
                            setPension(
                              e.target.value == "" ? 0 : e.target.value
                            );
                            setTotalPrice(
                              parseFloat(requestQty) +
                                parseFloat(tax) +
                                parseFloat(
                                  e.target.value == "" ? 0 : e.target.value
                                )
                            );
                          }}
                        />
                      </label>
                      <br />
                    </div>
                    <div style={{ display: "block", width: "300px" }}>
                      <label
                        style={{ fontSize: "14px", verticalAlign: "center" }}
                      >
                        Total Amount
                        <input
                          className="txt-input"
                          id="totalPrice"
                          type="text"
                          style={{ width: "150px", marginLeft: "20px" }}
                          value={
                            parseFloat(totalPrice).toLocaleString() + " Br"
                          }
                          defaultValue={0}
                          disabled
                        />
                      </label>
                    </div>
                    <input
                      type="button"
                      id="register"
                      value="Settle"
                      className="btn1"
                      style={{ margin: "5px 10px", width: "100px" }}
                      onClick={RegiBtn}
                    />
                  </>
                )}
              </div>
            </div>
            {/**Bottom table ==================================================================================== */}

            <table
              style={{
                width: "100%",
                minWidth: "600px",
                marginTop: "20px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: "45px",
                }}
              >
                <th>ፕሮጀክት</th>
                <th>ግብአት</th>
                <th>መለክያ</th>
                <th>ነጠላ ዋጋ</th>
                <th>የተላከ መጠን</th>
                <th>ጠቅላላ ዋጋ</th>
                <th>ታክስ</th>
                <th>ዊዝሆልድ</th>
                <th></th>
                <th></th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data2 != [] &&
                    data2.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[2]}</td>
                        <td> {list[3]}</td>
                        <td> {list[4]}</td>
                        <td> {parseFloat(list[5]).toLocaleString()}</td>
                        <td> {parseFloat(list[6]).toLocaleString()}</td>
                        <td>
                          {(
                            parseFloat(list[5]) * parseFloat(list[6])
                          ).toLocaleString()}
                        </td>
                        <td>
                          {(
                            (parseFloat(list[16]) + parseFloat(list[17])) *
                            parseFloat(list[6])
                          ).toLocaleString()}
                        </td>
                        <td> {parseFloat(list[18]).toLocaleString()}</td>
                        <td>
                          {list[13] == empId && (
                            <FontAwesomeIcon
                              className="menu-icon"
                              id="dlt-icon"
                              style={{
                                padding: "3px",
                                cursor: "pointer",
                                margin: "0",
                                padding: 0,
                                fontSize: "14px",
                                color: "gray",
                              }}
                              icon={faTrashCan}
                              onClick={() =>
                                DeleteReq(list[0], list[1], docNumber)
                              }
                            />
                          )}
                        </td>
                        <td>
                          <input
                            style={{ cursor: "pointer" }}
                            type="button"
                            value="ክፈት"
                            onClick={() => {
                              navigate("/expense_settle_detail", {
                                state: {
                                  id: list[0],
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}

                  <tr>
                    <td style={{ borderStyle: "none" }}></td>
                    <td style={{ borderStyle: "none" }}></td>
                    <td style={{ borderStyle: "none" }}></td>
                    <td style={{ borderStyle: "none" }}></td>
                    <td style={{ fontWeight: "bold" }}>ድምር</td>
                    <td style={{ fontWeight: "bold" }}>
                      {data2
                        .reduce((totAmt, item) => {
                          return (
                            totAmt + parseFloat(item[5]) * parseFloat(item[6])
                          );
                        }, 0)
                        .toLocaleString()}
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {data2
                        .reduce((totAmt, item) => {
                          return (
                            totAmt +
                            (parseFloat(item[16]) + parseFloat(item[17])) *
                              parseFloat(item[6])
                          );
                        }, 0)
                        .toLocaleString()}
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {data2
                        .reduce((totAmt, item) => {
                          return totAmt + parseFloat(item[18]);
                        }, 0)
                        .toLocaleString()}
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
}
export default ExpenseSettle;
