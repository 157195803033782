import React, { useState, useEffect } from "react";
import preLoader from "../img/preloader.gif";

function PreLoader() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        margin: 0,
        padding: 0,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{width:"300px", margin:"auto"}}>
        <h1 style={{ position: "absolute", zIndex: 1,  margin:"135px 120px", color:"white" }}>BDC</h1>
        <img
          src={preLoader}
          alt="loading..."
          style={{ width: "300px", margin:"auto" }}
        />
      </div>
    </div>
  );
}

export default PreLoader;
