import Navbar from "./components/navBar";
import Sidemenu from "./components/sideMenu";
import MainContCont from "./components/mainCont";
import { BrowserRouter, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "./components/login";
import { useState, useEffect } from "react";
import PreLoader from "./components/preLoader";

function App() {
  const isLogged = useSelector((state) => state.auth.isLogged);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {isLoading ? (
          <PreLoader />
        ) : (
          <>
            <Navbar />
            {isLogged ? (
              <div className="ext-cont">
                <Sidemenu /> 
                <MainContCont />
              </div>
            ) : (
              <LoginPage />
            )}
          </>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
