import axios from "axios";
import { React, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function EmpDetail() {
  const tab = useSelector((state) => state.auth.tab);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const empId = location.state.id;
  const idNumber = location.state.idNumber;
  const [editWindow, setEditWindow] = useState(false);
  const [editId, setEditId] = useState("");
  const [editValue, setEditValue] = useState("");
  const [deleteWindow, setDeleteWindow] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [empRole, setEmpRole] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState(""); // State for image source
  const [crop, setCrop] = useState();
  const imgRef = useRef(null);
  const [cropImageSrc, setCropImageSrc] = useState(); // Initial crop aspect ratio
  const MIN_DIM = 150;
  const ASPECT_RATIO = 1;

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "> Human Resource > Employee Detail";
    axios
      .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
        empId: empId,
      })
      .then((res) => {
        setData(res.data[0]);
      });

    axios
      .post("https://www.bdc.et/bdc-api/get_department_list.php", {})
      .then((res) => setDepartmentData(res.data))
      .catch((err) => alert(err));

    axios
      .post("https://www.bdc.et/bdc-api/get_employee_role2.php", {
        id: empId,
      })
      .then((res) => setEmpRole(res.data))
      .catch((err) => alert(err));
  }, []);

  const EditValue = () => {
    if (editValue == "") {
      document.getElementById("editValueErr").style.display = "block";
      document.getElementById("editValue").style.borderColor = "red";
    } else {
      document.getElementById("editValueErr").style.display = "none";
      document.getElementById("editValue").style.borderColor = "lightgray";
    }

    if (editValue != "") {
      axios
        .post("https://www.bdc.et/bdc-api/post_edit_employee.php", {
          empId: empId,
          editId: editId,
          editValue: editValue,
        })
        .then((res) => {
          setData(res.data[0]);
          setEditWindow(false);
        });
    }
  };

  const DeleteValue = () => {
    if (data[8] != "") {
      axios
        .post("https://www.bdc.et/bdc-api/post_delete_employee.php", {
          empId: data[8],
        })
        .then(navigate("/employee_list", {}));
    }
  };

  /** Image upload======================================================================================= */
  const OnSelect = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageUrl = reader.result?.toString() || "";
      setImageSrc(imageUrl);
      setOpenDialog(true);
    });
    reader.readAsDataURL(file);
  };

  const OnImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const crop = makeAspectCrop(
      {
        unit: "px",
        width: 300,
      },
      1,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const makeCroppedImage = () => {
    const img = new Image();
    img.src = imageSrc;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    const scaleX = img.naturalWidth / imgRef.current.width;
    const scaleY = img.naturalHeight / imgRef.current.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      img,
      0,
      0,
      img.naturalWidth,
      img.naturalHeight,
      0,
      0,
      img.naturalWidth,
      img.naturalHeight
    );

    console.log(cropX);
    console.log(cropY);

    return canvas;
  };

  const SubmitCrop = () => {
    const pixelCrop = makeCroppedImage();

    setCropImageSrc(pixelCrop.toDataURL("image/jpeg"));

    axios
      .post("https://www.bdc.et/bdc-api/post_edit_employee_pp.php", {
        empId: empId,
        pImage: pixelCrop.toDataURL("image/jpeg"),
      })
      .then((res) => {
        setData(res.data[0]);
      });

    setOpenDialog(false);
  };

  return (
    <>
      {tab.includes("tab11") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div
                style={{
                  width: "90%",
                  height: "100%",
                  zIndex: 2,
                  position: "absolute",
                  textAlign: "center",
                  marginLeft: "-150px",
                }}
              >
                <div
                  className="dialog-cont"
                  style={{
                    width: "50%",
                    height: "90%",
                    maxWidth: "90%",
                    maxHeight: "90%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <h2
                    style={{
                      paddingLeft: "20px",
                      color: "gray",
                      textAlign: "left",
                    }}
                  >
                    Crop image
                  </h2>
                  {imageSrc && (
                    <>
                      <ReactCrop
                        crop={crop}
                        onChange={(pixelCrop) => {
                          setCrop(pixelCrop);
                        }}
                        circularCrop
                        keepSelection
                        aspect={ASPECT_RATIO}
                        minWidth={MIN_DIM}
                      >
                        <img
                          ref={imgRef}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "60vh",
                          }}
                          src={imageSrc}
                          alt="profile image"
                          onLoad={OnImageLoad}
                        />
                      </ReactCrop>
                      <br />
                      <div style={{ display: "inline-flex" }}>
                        <button
                          onClick={SubmitCrop}
                          className="btn1"
                          style={{ width: "100px", marginBottom: "10px" }}
                        >
                          Crop Image
                        </button>
                        <button
                          onClick={() => {
                            setOpenDialog(false);
                          }}
                          className="btn2"
                          style={{
                            width: "100px",
                            marginBottom: "10px",
                            marginLeft: "20px",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {deleteWindow ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{ marginTop: "60px", textAlign: "center" }}
                >
                  <p
                    id="deleteVerify"
                    style={{
                      margin: "10px ",
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                    }}
                  >
                    *Are you sure you want to delete this employee? You can't
                    undo this action!
                  </p>
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <input
                      type="button"
                      id="edit"
                      value="Delete"
                      className="btn1"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={DeleteValue}
                    />
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn2"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={() => setDeleteWindow(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {editWindow ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{ marginTop: "60px", textAlign: "center" }}
                >
                  <p
                    id="editValueErr"
                    style={{
                      margin: "10px 0 -10px 0",
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                      display: "none",
                    }}
                  >
                    *Required Information
                  </p>
                  {editId == "Sex" ||
                  editId == "Department" ||
                  editId == "Employment Status" ? (
                    <>
                      {editId == "Sex" && (
                        <select
                          onChange={(e) => setEditValue(e.target.value)}
                          className="txt-input"
                          style={{
                            marginTop: "20px",
                            width: "95%",
                          }}
                          defaultValue={editValue}
                          id="editValue"
                        >
                          <option value="" disabled>
                            Select Sex
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      )}
                      {editId == "Department" && (
                        <select
                          onChange={(e) => setEditValue(e.target.value)}
                          className="txt-input"
                          style={{
                            marginTop: "20px",
                            width: "95%",
                          }}
                          defaultValue={editValue}
                          id="editValue"
                        >
                          <option value="" disabled>
                            Select Department
                          </option>
                          {departmentData &&
                            departmentData.map((dep) => (
                              <option value={dep[1]}>{dep[1]}</option>
                            ))}
                        </select>
                      )}
                      {editId == "Employment Status" && (
                        <select
                          onChange={(e) => setEditValue(e.target.value)}
                          className="txt-input"
                          style={{
                            marginTop: "20px",
                            width: "95%",
                          }}
                          defaultValue={editValue}
                          id="editValue"
                        >
                          <option value="" disabled>
                            Select Employment Status
                          </option>
                          <option value="Permanent">Permanent</option>
                          <option value="Contract">Contract</option>
                          <option value="Part Time">Part Time</option>
                          <option value="Remote">Remote</option>
                        </select>
                      )}
                    </>
                  ) : (
                    <input
                      className="txt-input"
                      id="editValue"
                      type={
                        editId == "Birth Date"
                          ? "date"
                          : editId == "Phone Number"
                          ? "number"
                          : "text"
                      }
                      style={{ marginTop: "20px" }}
                      defaultValue={editValue}
                      onChange={(e) => {
                        setEditValue(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          document.getElementById("edit").click();
                        }
                      }}
                      placeholder={"Enter " + editId}
                      required
                    />
                  )}

                  <br />
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <input
                      type="button"
                      id="edit"
                      value="Edit"
                      className="btn1"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={EditValue}
                    />
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn2"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={() => setEditWindow(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {/** Main container===================================================================================================== */}
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              minWidth: "800px",
              height: "97%",
              overflow: "auto",
            }}
          >
            <div
              style={{
                height: "90%",
                width: "35%",
              }}
            >
              <div
                className="cont-all"
                style={{
                  padding: "0",
                }}
              >
                {data != [] && (
                  <>
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        padding: "10px 0",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="brdr-all"
                        style={{
                          width: "150px",
                          height: "150px",
                          borderRadius: "60%",
                          margin: "auto",
                          float: "center",
                        }}
                      >
                        <img
                          src={
                            "https://www.bdc.et/bdc-api/uploads/profile-pictures/" +
                            data[17]
                          }
                          alt="profile-picture"
                          style={{
                            width: "140px",
                            height: "140px",
                            borderRadius: "60%",
                            margin: "5px",
                            float: "center",
                          }}
                        />
                      </div>
                      <label htmlFor="file-input" style={{ width: "100%" }}>
                        <p
                          style={{
                            margin: "0 auto",
                            padding: 0,
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "green",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          የሰራተኛ ፎቶ ለመቀየር
                        </p>
                        <input
                          id="file-input"
                          type="file"
                          onChange={OnSelect}
                          style={{ display: "none" }}
                        />
                      </label>
                    </div>

                    <div style={{ marginLeft: "20px" }}>
                      <div style={{ width: "100%", display: "flex" }}>
                        {" "}
                        <h3 style={{ color: "gray" }}>{data[1]}</h3> <br />
                        <FontAwesomeIcon
                          className="menu-icon brdr-all"
                          style={{
                            color: "green",
                            padding: "3px",
                            borderColor: "green",
                            cursor: "pointer",
                            fontSize: "10px",
                          }}
                          icon={faPen}
                          onClick={() => {
                            setEditId("First Name");
                            setEditValue(data[1]);
                            setEditWindow(true);
                          }}
                        />
                        <h3 style={{ color: "gray" }}>{data[2]}</h3>{" "}
                        <FontAwesomeIcon
                          className="menu-icon brdr-all"
                          style={{
                            color: "green",
                            padding: "3px",
                            borderColor: "green",
                            cursor: "pointer",
                            fontSize: "10px",
                          }}
                          icon={faPen}
                          onClick={() => {
                            setEditId("Middle Name");
                            setEditValue(data[2]);
                            setEditWindow(true);
                          }}
                        />
                        <h3 style={{ color: "gray" }}>{data[3]}</h3>{" "}
                        <FontAwesomeIcon
                          className="menu-icon brdr-all"
                          style={{
                            color: "green",
                            padding: "3px",
                            borderColor: "green",
                            cursor: "pointer",
                            fontSize: "10px",
                          }}
                          icon={faPen}
                          onClick={() => {
                            setEditId("Last Name");
                            setEditValue(data[3]);
                            setEditWindow(true);
                          }}
                        />
                      </div>
                      <div
                        className="brdr-btm"
                        style={{
                          display: "inline-flex",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            opacity: 0.3,
                            width: "100px",
                            fontSize: "14px",
                          }}
                        >
                          ፆታ{" "}
                        </p>
                        <p
                          style={{
                            fontWeight: "normal",
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          {data[4]}
                        </p>
                        <p
                          style={{
                            color: "green",
                            fontSize: "14px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEditId("Sex");
                            setEditValue(data[4]);
                            setEditWindow(true);
                          }}
                        >
                          edit
                        </p>
                      </div>
                      <br />
                      <div
                        className="brdr-btm"
                        style={{
                          display: "inline-flex",
                          marginBottom: "20px",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            opacity: 0.3,
                            fontSize: "14px",
                          }}
                        >
                          የትውልድ ቀን{" "}
                        </p>
                        <p
                          style={{
                            fontWeight: "normal",
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          {data[5]}
                        </p>
                        <p
                          style={{
                            color: "green",
                            fontSize: "14px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEditId("Birth Date");
                            setEditValue(data[5]);
                            setEditWindow(true);
                          }}
                        >
                          edit
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <br />
              <div style={{ display: "inline-flex", marginLeft: "10px" }}>
                <div
                  className="btn1"
                  style={{
                    width: "150px",
                    textAlign: "center",
                    justifyContent: "center",
                    marginLeft: "20px",
                    display: "inline-flex",
                  }}
                  onClick={() => setDeleteWindow(true)}
                >
                  <FontAwesomeIcon
                    className="menu-icon"
                    id="dlt-icon"
                    style={{
                      padding: "3px",
                      cursor: "pointer",
                      margin: "0",
                      padding: 0,
                      fontSize: "14px",
                    }}
                    icon={faTrashCan}
                    onClick={() => {}}
                  />
                  <p
                    style={{
                      margin: "0",
                      padding: 0,
                      marginLeft: "10px",
                    }}
                  >
                    Delete
                  </p>
                </div>
              </div>
            </div>

            <div
              className="cont-all"
              style={{
                width: "35%",
                padding: "0",
                marginLeft: "10px",
              }}
            >
              <h3
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  margin: 0,
                  padding: "5px 0px 5px 20px",
                }}
              >
                የሰራተኛ መረጃ
              </h3>
              {data != [] && (
                <>
                  <div style={{ marginLeft: "20px" }}>
                    <div
                      className="brdr-btm"
                      style={{
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          opacity: 0.3,
                          width: "120px",
                          fontSize: "14px",
                        }}
                      >
                        ስልክ ቁጥር{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                          fontSize: "14px",
                        }}
                      >
                        {data[6]}
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditId("Phone Number");
                          setEditValue(data[6]);
                          setEditWindow(true);
                        }}
                      >
                        ለመቀየር
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          opacity: 0.3,
                          width: "120px",
                          fontSize: "14px",
                        }}
                      >
                        ኢሜል{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                          fontSize: "14px",
                        }}
                      >
                        {data[7]}
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditId("Email");
                          setEditValue(data[7]);
                          setEditWindow(true);
                        }}
                      >
                        ለመቀየር
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          opacity: 0.3,
                          width: "120px",
                          fontSize: "14px",
                        }}
                      >
                        የመ. ቁጥር{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "black",
                        }}
                      >
                        {data[8]}
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditId("ID");
                          setEditValue(data[8]);
                          setEditWindow(true);
                        }}
                      >
                        ለመቀየር
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          opacity: 0.3,
                          width: "120px",
                          fontSize: "14px",
                        }}
                      >
                        መምርያ{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "black",
                        }}
                      >
                        {data[9]}
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditId("Department");
                          setEditValue(data[9]);
                          setEditWindow(true);
                        }}
                      >
                        ለመቀየር
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          opacity: 0.3,
                          width: "120px",
                          fontSize: "14px",
                        }}
                      >
                        ክፍል{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "black",
                        }}
                      >
                        {data[10]}
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditId("Section");
                          setEditValue(data[10]);
                          setEditWindow(true);
                        }}
                      >
                        ለመቀየር
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          opacity: 0.3,
                          width: "120px",
                          fontSize: "14px",
                        }}
                      >
                        የስራ መደብ{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "black",
                        }}
                      >
                        {data[11]}
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditId("Position");
                          setEditValue(data[11]);
                          setEditWindow(true);
                        }}
                      >
                        ለመቀየር
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          opacity: 0.3,
                          width: "120px",
                          fontSize: "14px",
                        }}
                      >
                        የቅጥር ሁኔታ{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "black",
                        }}
                      >
                        {data[15]}
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditId("Employment Status");
                          setEditValue(data[15]);
                          setEditWindow(true);
                        }}
                      >
                        ለመቀየር
                      </p>
                    </div>
                    <br />
                    <p
                      style={{
                        margin: "10px",
                        padding: 0,
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "green",
                        textAlign: "left",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditId("Password");
                        setEditValue("");
                        setEditWindow(true);
                      }}
                    >
                      የሚስጥር ቁጥር ለመቀየር
                    </p>
                  </div>
                </>
              )}
            </div>
            <div
              className="cont-all"
              style={{
                width: "35%",
                padding: "0",
                marginLeft: "10px",
              }}
            >
              <h3
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  margin: 0,
                  padding: "5px 0px 5px 20px",
                }}
              >
                የሰራተኛ የስራ ድርሻ
              </h3>
              {empRole != "" ? (
                empRole.map((list) => (
                  <li style={{ marginLeft: "20px", marginTop: "5px" }}>
                    {list[5]}
                  </li>
                ))
              ) : (
                <p style={{ color: "red", marginLeft: "20px" }}>
                  የስራ ድርሻ አልተሰጠውም!
                </p>
              )}
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "green",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/add_privilege", {
                    state: {
                      id: empId,
                      idNumber: idNumber,
                      fullName: data[1] + " " + data[2] + " " + data[3],
                    },
                  });
                }}
              >
                {empRole != "" ? "የስራ ድርሻ ለመቀየር" : "የስራ ድርሻ ለመስጠት"}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EmpDetail;
